import {
    EditToDo,
    FileUploadAnswer,
    OperationsState,
    OperationsStatePart,
    PreFinishToDoResult,
    ToDoReview,
    ToDoType
} from './types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FetchStatus, logger, normalize} from '@software/reactcommons';
import {
    changeReviewDeadlineActionAndSaga,
    ChangeReviewDeadlineRequestBody,
    changeToDoDeadlineActionAndSaga,
    ChangeToDoDeadlineRequestBody,
    createManualToDoActionAndSaga,
    declineToDoReviewActionAndSaga,
    DeleteFailedOperationsFileUploadPayload,
    deleteOperationsFileActionAndSaga,
    DeleteOperationsFilePayload,
    downloadToDosAction,
    finishAutoApproveToDoActionAndSaga,
    finishAutoDeclineToDoActionAndSaga,
    FinishOperationsFileUploadPayload,
    finishToDoActionAndSaga,
    finishToDoReviewActionAndSaga,
    loadApprovedRequestsAction,
    loadArchiveToDoActionAndSaga,
    loadAvailableToDosActionAndSaga,
    loadDeclinedRequestsAction, loadExpiredRequestsAction,
    loadFinishedToDoReviewActionAndSaga,
    loadFinishedToDosAction,
    loadManualToDosActionAndSaga,
    loadOpenToDosAction,
    loadOpenToDosForToDoDefinitionAndLocationActionAndSaga,
    loadOperationsEditorsAction,
    loadPreviousToDosForToDoActionAndSaga,
    loadRequestsToDosAction,
    loadReviewRequestsAction,
    loadReviewToDosAction,
    loadSubordinatedToDosAction,
    loadSubordinatedUserReviewRequestsAction,
    loadSubordinatedUserReviewToDosAction,
    loadToDoActionAndSaga,
    loadToDoReviewActionAndSaga,
    loadUserReviewRequestsAction,
    loadUserReviewToDosAction,
    LockToDoTaskPayload,
    preFinishToDoActionAndSaga,
    reloadToDoActionAndSaga,
    reOpenReviewActionAndSaga,
    reOpenToDoActionAndSaga,
    saveOperationsEditorsAction,
    SaveOperationsReviewerSuccessPayload,
    saveOperationsToDoReviewerActionAndSaga,
    saveToDoReviewRemarkAction,
    SaveToDoReviewRemarkBody,
    sendContinueEmailActionAndSaga,
    sendOperationsToDoEmailAction,
    SetOperationsFileUploadProgressPayload,
    setOperationsTaskAnswer,
    SetToDoUpdatedTimestampPayload,
    syncOperationsToDoAction,
    uploadOperationsFilesAction,
    WebSocketRemoveToDoAnswerPayload,
    WebSocketUpdateToDoAnswerPayload
} from './actions';
import {DateTime} from 'luxon';
import {cloneDeep} from 'lodash';
import {AttachmentType} from '../../../types/charts/chartTypes';

export const initialOperationsState: OperationsState = {
    overview: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 256,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    requestOverview: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 256,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    finishedToDos: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 12,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    approvedRequests: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 12,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    expiredRequests: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 12,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    declinedRequests: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 12,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    reviewToDos: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 10,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    reviewRequests: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 256,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    userReviewToDos: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 24,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    userReviewRequests: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 24,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    subordinatedToDos: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 6,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    subordinatedUserReviewToDos: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 6,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    subordinatedUserReviewRequests: {
        fetchStatus: FetchStatus.Default,
        elements: {},
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 6,
        search: '',
        visible: [],
        locationIds: [],
        toDoIds: []
    },
    reviews: {
        elements: {},
        fetchStatus: {},
        declineFetchStatus: {},
        declineConfigurations: {},
        approvalConfigurations: {},
    },
    finishedReviews: {
        elements: {},
        fetchStatus: {}
    },
    toDoGroups: {
        elements: {},
        toDosByGroup: {}
    },
    editorSearch: {
        elements: {},
        searchResult: {},
        selected: [],
        fetchStatus: FetchStatus.Default
    },
    manualToDos: {
        elements: {}
    },
    availableToDos: {
        elements: {}
    },
    editToDos: {
        elements: {},
        fetchStatus: {}
    },
    archiveToDos: {
        elements: {},
        fetchStatus: {}
    },
    previousToDos: {
        elements: {},
        fetchStatus: {}
    },
    fetchStatus: {
        manualToDos: FetchStatus.Default,
        availableToDos: FetchStatus.Default,
        createManualToDo: FetchStatus.Default,
        continueEmail: FetchStatus.Default,
        openToDosByToDoDefinitionAndLocation: FetchStatus.Default
    }
};


const setReducedToDoFetchStatus = (state: OperationsState, ids: number[], status: FetchStatus,
                                   part: OperationsStatePart,
                                   key: 'downloadFetchStatus' | 'editEditorFetchStatus' | 'sendEmailFetchStatus' | 'changeDeadlineFetchStatus'
                                       | 'editReviewerFetchStatus' | 'changeReviewDeadlineFetchStatus' | 'reOpenFetchStatus') => {
    ids.forEach(it => {
        // Assume that the ids
        const element = state[part].elements[it];
        if (element) {
            element[key] = status;
        }
    });
}

const operations = createSlice({
    name: 'operations',
    initialState: initialOperationsState,
    reducers: {
        setOperationsPendingToDosSearch: (state, action: PayloadAction<string>) => {
            state.overview.search = action.payload;
            state.overview.page = 0;
        },
        setOperationsPendingToDosPage: (state, action: PayloadAction<number>) => {
            state.overview.page = action.payload;
        },
        setOperationsEditEditor: (state, action: PayloadAction<number[]>) => {
            state.editorSearch.selected = action.payload;
        },
        resetOperationsEditEditors: state => {
            state.editorSearch = {...initialOperationsState.editorSearch};
        },
        setOperationsFinishedToDosSearch: (state, action: PayloadAction<string>) => {
            state.finishedToDos.search = action.payload;
            state.finishedToDos.page = 0;
            state.finishedToDos.visible = [];
        },
        setOperationsFinishedToDosPage: (state, action: PayloadAction<number>) => {
            state.finishedToDos.page = action.payload;
        },
        setOperationsRequestsPage: (state, action: PayloadAction<number>) => {
            state.requestOverview.page = action.payload;
        },
        setOperationsPendingToDosLocationIds: (state, action: PayloadAction<number[]>) => {
            state.overview.locationIds = action.payload;
            state.finishedToDos.page = 0;
            state.finishedToDos.visible = [];
        },
        setOperationsFinishedToDosLocationIds: (state, action: PayloadAction<number[]>) => {
            state.finishedToDos.locationIds = action.payload;
            state.finishedToDos.page = 0;
            state.finishedToDos.visible = [];
        },
        setOperationsPendingToDosToDoIds: (state, action: PayloadAction<number[]>) => {
            state.overview.toDoIds = action.payload;
            state.overview.page = 0;
            state.overview.visible = [];
        },
        setOperationsFinishedToDosToDoIds: (state, action: PayloadAction<number[]>) => {
            state.finishedToDos.toDoIds = action.payload;
            state.finishedToDos.page = 0;
            state.finishedToDos.visible = [];
        },
        setOperationsRequestsToDoIds: (state, action: PayloadAction<number[]>) => {
            state.requestOverview.toDoIds = action.payload;
            state.requestOverview.page = 0;
            state.requestOverview.visible = [];
        },
        setOperationsApprovedRequestsToDoIds: (state, action: PayloadAction<number[]>) => {
            state.approvedRequests.toDoIds = action.payload;
            state.approvedRequests.page = 0;
            state.approvedRequests.visible = [];
        },
        setOperationsDeclinedRequestsToDoIds: (state, action: PayloadAction<number[]>) => {
            state.declinedRequests.toDoIds = action.payload;
            state.declinedRequests.page = 0;
            state.declinedRequests.visible = [];
        },
        setOperationsExpiredRequestsToDoIds: (state, action: PayloadAction<number[]>) => {
            state.expiredRequests.toDoIds = action.payload;
            state.expiredRequests.page = 0;
            state.expiredRequests.visible = [];
        },
        moveToDoToFinishedToDo: (state, action: PayloadAction<number>) => {
            const toDo = state.overview.elements[action.payload];
            if (toDo?.type === ToDoType.Report) {
                // Remove to do from overview
                state.overview.visible = [...state.overview.visible].filter(it => it !== String(action.payload));
                // Spread it to create new object because reference in overview will be deleted afterwards
                state.finishedToDos.elements[action.payload] = {...toDo};
                delete state.overview.elements[action.payload];
            }
            // Remove the to do from the edit to dos state
            delete state.editToDos.elements[action.payload];
            return state;
        },
        moveToDoToRequests: (state, action: PayloadAction<{ id: number, approved: boolean; }>) => {
            const toDo = state.requestOverview.elements[action.payload.id];
            if (toDo?.type === ToDoType.Request) {
                // Remove to do from overview
                state.requestOverview.visible = [...state.requestOverview.visible].filter(it => it !== String(action.payload));
                // Spread it to create new object because reference in overview will be deleted afterwards
                if (action.payload.approved) {
                    state.approvedRequests.elements[action.payload.id] = {...toDo};
                } else {
                    state.declinedRequests.elements[action.payload.id] = {...toDo};
                }
                delete state.requestOverview.elements[action.payload.id];
            }
            // Remove the to do from the edit to dos state
            delete state.editToDos.elements[action.payload.id];
            return state;
        },
        setOperationsFileUploadProgress: (state, action: PayloadAction<SetOperationsFileUploadProgressPayload>) => {
            state.editToDos.elements[action.payload.toDoId]!.activeFileUploads[action.payload.questionId]![action.payload.fileId]!.progress = action.payload.progress;
        },
        deleteFailedOperationsFileUpload: (state, action: PayloadAction<DeleteFailedOperationsFileUploadPayload>) => {
            if (state.editToDos.elements[action.payload.toDoId]?.errorFileUploads[action.payload.questionId]?.[action.payload.fileId]) {
                delete state.editToDos.elements[action.payload.toDoId]!.errorFileUploads[action.payload.questionId]![action.payload.fileId];
            }
        },
        deleteFinishedOperationsFileUploads: (state, action: PayloadAction<FinishOperationsFileUploadPayload>) => {
            action.payload.fileIds.forEach(it => {
                // Delete the file ids which have been successfully uploaded
                delete state.editToDos.elements[action.payload.toDoId]!.activeFileUploads[action.payload.questionId]![it];
                delete state.editToDos.elements[action.payload.toDoId]!.finishedFileUploads[action.payload.questionId]?.[it];
            });
        },
        deleteOperationsFileFromAnswer: (state, action: PayloadAction<DeleteOperationsFilePayload>) => {
            const answer = state.editToDos.elements[action.payload.toDoId]!.answers.find(it => it.questionID === action.payload.questionId);
            if (answer && (answer as FileUploadAnswer).files?.length) {
                // Filter the file with the path of the deleted file
                (answer as FileUploadAnswer).files = (answer as FileUploadAnswer).files?.filter(it => it.path !== action.payload.path) || [];
            } else {
                logger.error(`Could not find attachment for file ${action.payload.path}`)
            }
        },
        setToDoLastUpdatedTimestamp: (state, action: PayloadAction<SetToDoUpdatedTimestampPayload>) => {
            const toDo = state.editToDos.elements[action.payload.toDoId];
            if (toDo) {
                toDo.lastUpdateTimestamp = action.payload.timestamp;
            }
        },
        setTaskLastUserChanged: (state, action: PayloadAction<LockToDoTaskPayload>) => {
            const toDo = state.editToDos.elements[action.payload.toDoId];
            if (toDo) {
                toDo.taskLastUserChanged[action.payload.taskId] = action.payload.timestamp;
            }
        },
        setOperationsReviewRemark: (state, action: PayloadAction<SaveToDoReviewRemarkBody>) => {
            const toDo = state.reviews.elements[action.payload.toDoId];
            if (toDo) {
                const remark = toDo.remarks?.find(it => it.taskId === action.payload.remark.taskId);
                if (remark) {
                    remark.remark = action.payload.remark.remark;
                    remark.approved = action.payload.remark.approved;
                } else {
                    if (!toDo.remarks) {
                        toDo.remarks = [];
                    }
                    toDo.remarks.push({
                        remark: action.payload.remark.remark,
                        toDoId: action.payload.toDoId,
                        taskId: action.payload.remark.taskId,
                        approved: action.payload.remark.approved
                    });
                }
            }
        },
        setOperationsReviewToDosSearch: (state, action: PayloadAction<string>) => {
            state.reviewToDos.search = action.payload;
            state.reviewToDos.page = 0;
            state.reviewToDos.visible = [];
        },
        setOperationsReviewRequestsSearch: (state, action: PayloadAction<string>) => {
            state.reviewRequests.search = action.payload;
            state.reviewRequests.page = 0;
            state.reviewRequests.visible = [];
        },
        setOperationsUserReviewToDosSearch: (state, action: PayloadAction<string>) => {
            state.userReviewToDos.search = action.payload;
            state.userReviewToDos.page = 0;
            state.userReviewToDos.visible = [];
        },
        setOperationsUserReviewRequestsSearch: (state, action: PayloadAction<string>) => {
            state.userReviewRequests.search = action.payload;
            state.userReviewRequests.page = 0;
            state.userReviewRequests.visible = [];
        },
        setOperationsSubordinatedToDosSearch: (state, action: PayloadAction<string>) => {
            state.subordinatedToDos.search = action.payload;
            state.subordinatedToDos.page = 0;
            state.subordinatedToDos.visible = [];
        },
        setOperationsSubordinatedUserReviewToDosSearch: (state, action: PayloadAction<string>) => {
            state.subordinatedUserReviewToDos.search = action.payload;
            state.subordinatedUserReviewToDos.page = 0;
            state.subordinatedUserReviewToDos.visible = [];
        },
        setOperationsSubordinatedUserReviewRequestsSearch: (state, action: PayloadAction<string>) => {
            state.subordinatedUserReviewRequests.search = action.payload;
            state.subordinatedUserReviewRequests.page = 0;
            state.subordinatedUserReviewRequests.visible = [];
        },
        setOperationsRequestsToDosSearch: (state, action: PayloadAction<string>) => {
            state.requestOverview.search = action.payload;
            state.requestOverview.page = 0;
            state.requestOverview.visible = [];
        },
        setOperationsApprovedRequestsSearch: (state, action: PayloadAction<string>) => {
            state.approvedRequests.search = action.payload;
            state.approvedRequests.page = 0;
            state.approvedRequests.visible = [];
        },
        setOperationsDeclinedRequestsSearch: (state, action: PayloadAction<string>) => {
            state.declinedRequests.search = action.payload;
            state.declinedRequests.page = 0;
            state.declinedRequests.visible = [];
        },
        setOperationsExpiredRequestsSearch: (state, action: PayloadAction<string>) => {
            state.expiredRequests.search = action.payload;
            state.expiredRequests.page = 0;
            state.expiredRequests.visible = [];
        },
        webSocketRemoveToDoAnswers: (state, action: PayloadAction<WebSocketRemoveToDoAnswerPayload>) => {
            const toDo = state.editToDos.elements[action.payload.toDoId];
            if (toDo) {
                action.payload.answerIds.forEach(it => {
                    const lastChangeTimestamp = toDo.taskLastUserChanged[it] || 0;
                    const lastSyncedTimestamp = toDo.taskLastSyncStarted[it] || 0;
                    if (lastChangeTimestamp <= lastSyncedTimestamp || lastChangeTimestamp < action.payload.serverTimestamp) {
                        toDo.answers = toDo.answers.filter(answer => answer.questionID !== it);
                    }
                    // Update the question state from the payload.
                    delete toDo.questionState[it];
                });
            }
        },
        webSocketUpdateToDoAnswers: (state, action: PayloadAction<WebSocketUpdateToDoAnswerPayload>) => {
            // Action is triggered via web socket, possible that to do does not exist in state!
            const toDo = state.editToDos.elements[action.payload.toDoId];
            if (toDo) {
                action.payload.answers.forEach(answer => {
                    const lastChangeTimestamp = toDo.taskLastUserChanged[answer.questionID] || 0;
                    const lastSyncedTimestamp = toDo.taskLastSyncStarted[answer.questionID] || 0;
                    if (lastChangeTimestamp <= lastSyncedTimestamp || lastChangeTimestamp < action.payload.serverTimestamp) {
                        const existing = toDo.answers.find(it => it.questionID === answer.questionID);
                        if (existing) {
                            const timestamp = DateTime.fromFormat(existing.lastUpdatedAnswerTime, 'yyyy-MM-dd HH:mm:ss').toMillis();
                            if ((answer.version || 0) > (existing.version || 1)) {
                                logger.debug(`Updating answer via websocket for question ${answer.questionID} ${timestamp} vs. ${action.payload.serverTimestamp}.`);
                                toDo.answers = toDo.answers.filter(it => it.questionID !== answer.questionID);
                                toDo.answers.push({...answer, external: true});
                            } else {
                                // User already entered new answer or answer from message is equal to existing answer, so ignore the web socket message
                                logger.debug(`Not updating answer because answer already equal for question ${answer.questionID}`);
                            }
                        } else if (toDo.taskFetchStatus[answer.questionID] !== FetchStatus.Active) {
                            // Check first if question is currently fetching to avoid duplicate overriding of answer
                            toDo.answers.push(answer);
                        }
                    }
                });
                // Update the question state from the payload.
                toDo.questionState = {...toDo.questionState, ...action.payload.questionState};
            }
        },
        deleteReviewToDo: (state, action: PayloadAction<number>) => {
            delete state.reviews.elements[action.payload];
            delete state.userReviewToDos.elements[action.payload];
            delete state.reviewToDos.elements[action.payload];
        },
        moveToDosFromSubordinatedToOwnToDos: (state, action: PayloadAction<number[]>) => {
            action.payload.filter(it => Boolean(state.subordinatedToDos.elements[it])).forEach(it => {
                state.overview.elements[it] = {
                    ...state.subordinatedToDos.elements[it]!,
                    editEditorFetchStatus: FetchStatus.Default
                };
                delete state.subordinatedToDos.elements[it];
            });
            state.subordinatedToDos.visible = state.subordinatedToDos.visible.filter(it => !action.payload.includes(Number(it)));
        },
        updateToDoDeadlines: (state, action: PayloadAction<ChangeToDoDeadlineRequestBody>) => {
            setReducedToDoFetchStatus(state, action.payload.ids, FetchStatus.Success, action.payload.statePart, 'changeDeadlineFetchStatus');
            action.payload.ids.filter(it => Boolean(state[action.payload.statePart].elements[it])).forEach(it => {
                state[action.payload.statePart].elements[it] = {
                    ...state[action.payload.statePart].elements[it]!,
                    lastPossibleFinishingDate: DateTime.fromMillis(action.payload.deadline).toFormat('dd.MM.yyyy')
                }
            })
        },
        setOperationsUserReviewToDosPageAndPageSize: (state, action: PayloadAction<{
            page: number;
            pageSize: number
        }>) => {
            state.userReviewToDos.page = action.payload.page;
            state.userReviewToDos.pageSize = action.payload.pageSize;
        },
        setOperationsUserReviewRequestsPageAndPageSize: (state, action: PayloadAction<{
            page: number;
            pageSize: number
        }>) => {
            state.userReviewRequests.page = action.payload.page;
            state.userReviewRequests.pageSize = action.payload.pageSize;
        },
        setOperationsFinishedToDosPageAndPageSize: (state, action: PayloadAction<{
            page: number;
            pageSize: number
        }>) => {
            state.finishedToDos.page = action.payload.page;
            state.finishedToDos.pageSize = action.payload.pageSize;
        },
        setOperationsApprovedRequestsPageAndPageSize: (state, action: PayloadAction<{
            page: number;
            pageSize: number
        }>) => {
            state.approvedRequests.page = action.payload.page;
            state.approvedRequests.pageSize = action.payload.pageSize;
        },
        setOperationsDeclinedRequestsPageAndPageSize: (state, action: PayloadAction<{
            page: number;
            pageSize: number
        }>) => {
            state.declinedRequests.page = action.payload.page;
            state.declinedRequests.pageSize = action.payload.pageSize;
        },
        setOperationsExpiredRequestsPageAndPageSize: (state, action: PayloadAction<{
            page: number;
            pageSize: number
        }>) => {
            state.expiredRequests.page = action.payload.page;
            state.expiredRequests.pageSize = action.payload.pageSize;
        },
        setOperationsReviewRequestsPageAndPageSize: (state, action: PayloadAction<{
            page: number;
            pageSize: number
        }>) => {
            state.reviewRequests.page = action.payload.page;
            state.reviewRequests.pageSize = action.payload.pageSize;
        },
        setOperationsSubordinatedToDosPageAndPageSize: (state, action: PayloadAction<{
            page: number;
            pageSize: number
        }>) => {
            state.subordinatedToDos.page = action.payload.page;
            state.subordinatedToDos.pageSize = action.payload.pageSize;
        },
        setOperationsSubordinatedUserReviewToDosPageAndPageSize: (state, action: PayloadAction<{
            page: number;
            pageSize: number
        }>) => {
            state.subordinatedUserReviewToDos.page = action.payload.page;
            state.subordinatedUserReviewToDos.pageSize = action.payload.pageSize;
        },
        setOperationsSubordinatedUserReviewRequestsPageAndPageSize: (state, action: PayloadAction<{
            page: number;
            pageSize: number
        }>) => {
            state.subordinatedUserReviewRequests.page = action.payload.page;
            state.subordinatedUserReviewRequests.pageSize = action.payload.pageSize;
        },
        setOperationsSubordinatedToDosLocationIds: (state, action: PayloadAction<number[]>) => {
            state.subordinatedToDos.locationIds = action.payload;
            state.subordinatedToDos.visible = [];
            state.subordinatedToDos.page = 0;
        },
        setOperationsSubordinatedUserReviewToDosLocationIds: (state, action: PayloadAction<number[]>) => {
            state.subordinatedUserReviewToDos.locationIds = action.payload;
            state.subordinatedUserReviewToDos.visible = [];
            state.subordinatedUserReviewToDos.page = 0;
        },
        setOperationsSubordinatedUserReviewRequestsLocationIds: (state, action: PayloadAction<number[]>) => {
            state.subordinatedUserReviewRequests.locationIds = action.payload;
            state.subordinatedUserReviewRequests.visible = [];
            state.subordinatedUserReviewRequests.page = 0;
        },
        setOperationsUserReviewToDosLocationIds: (state, action: PayloadAction<number[]>) => {
            state.userReviewToDos.locationIds = action.payload;
            state.userReviewToDos.visible = [];
            state.userReviewToDos.page = 0;
        },
        setOperationsUserReviewRequestsLocationIds: (state, action: PayloadAction<number[]>) => {
            state.userReviewRequests.locationIds = action.payload;
            state.userReviewRequests.visible = [];
            state.userReviewRequests.page = 0;
        },
        setOperationsReviewToDosLocationIds: (state, action: PayloadAction<number[]>) => {
            state.reviewToDos.locationIds = action.payload;
            state.reviewToDos.visible = [];
            state.reviewToDos.page = 0;
        },
        setOperationsReviewRequestsLocationIds: (state, action: PayloadAction<number[]>) => {
            state.reviewRequests.locationIds = action.payload;
            state.reviewRequests.visible = [];
            state.reviewRequests.page = 0;
        },
        setOperationsRequestsLocationIds: (state, action: PayloadAction<number[]>) => {
            state.requestOverview.locationIds = action.payload;
            state.requestOverview.visible = [];
            state.requestOverview.page = 0;
        },
        setOperationsApprovedRequestsLocationIds: (state, action: PayloadAction<number[]>) => {
            state.approvedRequests.locationIds = action.payload;
            state.approvedRequests.visible = [];
            state.approvedRequests.page = 0;
        },
        setOperationsDeclinedRequestsLocationIds: (state, action: PayloadAction<number[]>) => {
            state.declinedRequests.locationIds = action.payload;
            state.declinedRequests.visible = [];
            state.declinedRequests.page = 0;
        },
        setOperationsExpiredRequestsLocationIds: (state, action: PayloadAction<number[]>) => {
            state.expiredRequests.locationIds = action.payload;
            state.expiredRequests.visible = [];
            state.expiredRequests.page = 0;
        },
        setOperationsSubordinatedToDosToDoIds: (state, action: PayloadAction<number[]>) => {
            state.subordinatedToDos.toDoIds = action.payload;
            state.subordinatedToDos.visible = [];
            state.subordinatedToDos.page = 0;
        },
        setOperationsSubordinatedUserReviewToDosToDoIds: (state, action: PayloadAction<number[]>) => {
            state.subordinatedUserReviewToDos.toDoIds = action.payload;
            state.subordinatedUserReviewToDos.visible = [];
            state.subordinatedUserReviewToDos.page = 0;
        },
        setOperationsSubordinatedUserReviewRequestsToDoIds: (state, action: PayloadAction<number[]>) => {
            state.subordinatedUserReviewRequests.toDoIds = action.payload;
            state.subordinatedUserReviewRequests.visible = [];
            state.subordinatedUserReviewRequests.page = 0;
        },
        setOperationsUserReviewToDosToDoIds: (state, action: PayloadAction<number[]>) => {
            state.userReviewToDos.toDoIds = action.payload;
            state.userReviewToDos.visible = [];
            state.userReviewToDos.page = 0;
        },
        setOperationsUserReviewRequestsToDoIds: (state, action: PayloadAction<number[]>) => {
            state.userReviewRequests.toDoIds = action.payload;
            state.userReviewRequests.visible = [];
            state.userReviewToDos.page = 0;
        },
        setOperationsReviewToDosToDoIds: (state, action: PayloadAction<number[]>) => {
            state.reviewToDos.toDoIds = action.payload;
            state.reviewToDos.visible = [];
            state.reviewToDos.page = 0;
        },
        setOperationsReviewRequestsToDoIds: (state, action: PayloadAction<number[]>) => {
            state.reviewRequests.toDoIds = action.payload;
            state.reviewRequests.visible = [];
            state.reviewRequests.page = 0;
        },
        setOperationsSaveReviewerSuccess: (state, action: PayloadAction<SaveOperationsReviewerSuccessPayload>) => {
            const element = state[action.payload.statePart].elements[action.payload.toDoId];
            if (element) {
                const review = element.reviews.find(it => !it.finishedAt);
                if (review) {
                    const emails = review.reviewers.map(it => it.email);
                    review.reviewers = [...review.reviewers, ...action.payload.users.filter(it => !emails.includes(it.email))];
                    element.editReviewerFetchStatus = FetchStatus.Success;
                }
            }
        },
        updateReviewDeadlines: (state, action: PayloadAction<ChangeReviewDeadlineRequestBody>) => {
            setReducedToDoFetchStatus(state, action.payload.reviews.map(it => it.toDoId), FetchStatus.Success, action.payload.statePart, 'changeReviewDeadlineFetchStatus');
            action.payload.reviews.filter(it => Boolean(state[action.payload.statePart].elements[it.toDoId])).forEach(it => {
                const reviews = state[action.payload.statePart].elements[it.toDoId]!.reviews;
                state[action.payload.statePart].elements[it.toDoId] = {
                    ...state[action.payload.statePart].elements[it.toDoId]!,
                    reviews: [...reviews.filter(review => review.id !== it.id), {
                        ...reviews.find(review => review.id === it.id)!,
                        deadline: DateTime.fromMillis(action.payload.deadline).toFormat('dd.MM.yyyy')
                    }]
                }
            });
        },
        resetToDoSearch: (state) => {
            (['finishedToDos', 'overview', 'subordinatedToDos', 'reviewToDos', 'userReviewToDos', 'subordinatedUserReviewToDos',
                'requestOverview', 'approvedRequests', 'expiredRequests', 'declinedRequests', 'reviewRequests', 'userReviewRequests',
                'subordinatedUserReviewRequests'] as OperationsStatePart[]).forEach(it => {
                state[it].toDoIds = [];
                state[it].locationIds = [];
                state[it].search = '';
                state[it].visible = [];
                state[it].page = 0;
            })
        },
        resetStatePartFetchStatus: (state, action: PayloadAction<OperationsStatePart>) => {
            state[action.payload].fetchStatus = FetchStatus.Default;
        },
        removeToDosFromFinishedToDos: (state, action: PayloadAction<number[]>) => {
            // Remove ids from visible ids
            state.finishedToDos.visible = state.finishedToDos.visible.filter(it => !action.payload.includes(Number(it)));
            // Delete elements from state
            action.payload.forEach(it => {
                delete state.finishedToDos.elements[it];
            });
        },
        disapproveReviewedTask: (state, action: PayloadAction<{ toDoId: number; taskId: number; }>) => {
            const review = state.reviews.elements[action.payload.toDoId];
            if (review) {
                const task = review.tasks.find(it => it.id === action.payload.taskId);
                if (task) {
                    task.approved = false;
                    task.requiresReview = true;
                }
            }
        },
        addPreviousToDosToState: (state, action: PayloadAction<{ toDoId: number; previousToDos: EditToDo[]; }>) => {
            state.previousToDos.elements[action.payload.toDoId] = action.payload.previousToDos;
            state.previousToDos.fetchStatus[action.payload.toDoId] = FetchStatus.Success;
        },
        declineToDoReviewSuccess: (state, action: PayloadAction<{ toDoId: number; review: ToDoReview; }>) => {
            state.reviews.declineFetchStatus[action.payload.toDoId] = FetchStatus.Success;
        },
        setToDoPreFinishSuccess: (state, action: PayloadAction<{ id: number; results: PreFinishToDoResult[]; }>) => {
            state.editToDos.elements[action.payload.id]!.fetchStatus.preFinish = FetchStatus.Success;
            state.editToDos.elements[action.payload.id]!.preFinishResults = action.payload.results;
        },
        setToDoReviewers: (state, action: PayloadAction<{ id: number; reviewers: string[] }>) => {
            state.editToDos.elements[action.payload.id]!.reviewedBy = action.payload.reviewers;
        }
    },
    extraReducers: builder =>
        builder.addCase(loadOpenToDosAction.startAction, state => {
            state.overview.fetchStatus = FetchStatus.Active;
        }).addCase(loadOpenToDosAction.errorAction, state => {
            state.overview.fetchStatus = FetchStatus.Error;
        }).addCase(loadOpenToDosAction.successAction, (state, action) => {
            state.overview.fetchStatus = FetchStatus.Success;
            state.overview.elements = {...state.overview.elements, ...normalize(action.payload.elements)};
            // Merge the received groups into the state
            state.toDoGroups.elements = {...state.toDoGroups.elements, ...normalize(action.payload.elements.filter(it => Boolean(it.group)).map(it => it.group!))};
            // Add the to dos to the group
            action.payload.elements.forEach(it => {
                if (it.group && !state.toDoGroups.toDosByGroup[it.group.id]?.includes(it.id)) {
                    state.toDoGroups.toDosByGroup[it.group.id] = [...(state.toDoGroups.toDosByGroup[it.group.id] || []), it.id];
                }
            });
            state.overview.numberOfElements = action.payload.totalCount!;
            state.overview.visible = action.payload.elements.map(it => it.id.toString(10));
        }).addCase(loadFinishedToDosAction.startAction, (state, action) => {
            state.finishedToDos.fetchStatus = FetchStatus.Active;
            if (action.payload.replace) {
                state.finishedToDos.visible = [];
            }
        }).addCase(loadFinishedToDosAction.errorAction, state => {
            state.finishedToDos.fetchStatus = FetchStatus.Error;
        }).addCase(loadFinishedToDosAction.successAction, (state, action) => {
            state.finishedToDos.fetchStatus = FetchStatus.Success;
            state.finishedToDos.elements = {...state.finishedToDos.elements, ...normalize(action.payload.elements)};
            state.finishedToDos.numberOfElements = action.payload.totalCount!;
            state.finishedToDos.visible = [...state.finishedToDos.visible, ...action.payload.elements.map(it => it.id.toString(10)).filter(it => !state.finishedToDos.visible.includes(it))];
        }).addCase(loadOperationsEditorsAction.startAction, state => {
            state.editorSearch.fetchStatus = FetchStatus.Active;
            state.editorSearch.searchResult = {};
        }).addCase(loadOperationsEditorsAction.errorAction, state => {
            state.editorSearch.fetchStatus = FetchStatus.Error;
            state.editorSearch.searchResult = {};
        }).addCase(loadOperationsEditorsAction.successAction, (state, action) => {
            state.editorSearch.fetchStatus = FetchStatus.Success;
            const normalized = normalize(action.payload);
            state.editorSearch.searchResult = normalized;
            state.editorSearch.elements = {...state.editorSearch.elements, ...normalized};
        }).addCase(loadOperationsEditorsAction.resetAction, state => {
            state.editorSearch.fetchStatus = FetchStatus.Default;
        }).addCase(saveOperationsEditorsAction.startAction, (state, action) => {
            setReducedToDoFetchStatus(state, [action.payload.toDoId], FetchStatus.Active, action.payload.statePart, 'editEditorFetchStatus');
        }).addCase(saveOperationsEditorsAction.errorAction, (state, action) => {
            setReducedToDoFetchStatus(state, [action.payload.toDoId], FetchStatus.Error, action.payload.statePart, 'editEditorFetchStatus');
        }).addCase(saveOperationsEditorsAction.resetAction, (state, action) => {
            setReducedToDoFetchStatus(state, [action.payload.toDoId], FetchStatus.Default, action.payload.statePart, 'editEditorFetchStatus');
        }).addCase(saveOperationsEditorsAction.successAction, (state, action) => {
            const element = state[action.payload.statePart].elements[action.payload.toDoId];
            if (element) {
                element.editors = [...element.editors, ...action.payload.emails];
                element.editEditorFetchStatus = FetchStatus.Success;
            }
        }).addCase(downloadToDosAction.startAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.toDos.map(it => it.id), FetchStatus.Active, 'finishedToDos', 'downloadFetchStatus');
        }).addCase(downloadToDosAction.errorAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.toDos.map(it => it.id), FetchStatus.Error, 'finishedToDos', 'downloadFetchStatus');
        }).addCase(downloadToDosAction.successAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.toDos.map(it => it.id), FetchStatus.Success, 'finishedToDos', 'downloadFetchStatus');
        }).addCase(downloadToDosAction.resetAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.toDos.map(it => it.id), FetchStatus.Default, 'finishedToDos', 'downloadFetchStatus');
        }).addCase(sendOperationsToDoEmailAction.startAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.ids, FetchStatus.Active, 'finishedToDos', 'sendEmailFetchStatus')
        }).addCase(sendOperationsToDoEmailAction.errorAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.ids, FetchStatus.Error, 'finishedToDos', 'sendEmailFetchStatus')
        }).addCase(sendOperationsToDoEmailAction.successAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload, FetchStatus.Success, 'finishedToDos', 'sendEmailFetchStatus')
        }).addCase(sendOperationsToDoEmailAction.resetAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload, FetchStatus.Default, 'finishedToDos', 'sendEmailFetchStatus')
        }).addCase(loadManualToDosActionAndSaga.startAction, (state) => {
            state.fetchStatus.manualToDos = FetchStatus.Active;
        }).addCase(loadManualToDosActionAndSaga.errorAction, (state) => {
            state.fetchStatus.manualToDos = FetchStatus.Error;
        }).addCase(loadManualToDosActionAndSaga.resetAction, (state) => {
            state.fetchStatus.manualToDos = FetchStatus.Default;
        }).addCase(loadManualToDosActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.manualToDos = FetchStatus.Success;
            state.manualToDos.elements = {...state.manualToDos.elements, ...normalize(action.payload.elements)};
        }).addCase(createManualToDoActionAndSaga.startAction, (state) => {
            state.fetchStatus.createManualToDo = FetchStatus.Active;
        }).addCase(createManualToDoActionAndSaga.errorAction, (state) => {
            state.fetchStatus.createManualToDo = FetchStatus.Error;
        }).addCase(createManualToDoActionAndSaga.resetAction, (state) => {
            state.fetchStatus.createManualToDo = FetchStatus.Default;
        }).addCase(createManualToDoActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.createManualToDo = FetchStatus.Success;
            if (action.payload.type === ToDoType.Request) {
                state.requestOverview.elements[action.payload.id] = action.payload;
                // Remove the oldest element in the visible to dos and append the newly created to do as first element
                state.requestOverview.visible = [action.payload.id.toString(10), ...state.requestOverview.visible.slice(0, state.requestOverview.numberOfElements - 1)];
            } else {
                state.overview.elements[action.payload.id] = action.payload;
                // Remove the oldest element in the visible to dos and append the newly created to do as first element
                state.overview.visible = [action.payload.id.toString(10), ...state.overview.visible.slice(0, state.overview.numberOfElements - 1)];
            }
            state.manualToDos.createdToDoId = action.payload.id;
        }).addCase(sendContinueEmailActionAndSaga.startAction, state => {
            state.fetchStatus.continueEmail = FetchStatus.Active;
        }).addCase(sendContinueEmailActionAndSaga.errorAction, state => {
            state.fetchStatus.continueEmail = FetchStatus.Error;
        }).addCase(sendContinueEmailActionAndSaga.successAction, state => {
            state.fetchStatus.continueEmail = FetchStatus.Success;
        }).addCase(sendContinueEmailActionAndSaga.resetAction, state => {
            state.fetchStatus.continueEmail = FetchStatus.Default;
        }).addCase(loadAvailableToDosActionAndSaga.startAction, state => {
            state.fetchStatus.availableToDos = FetchStatus.Active;
        }).addCase(loadAvailableToDosActionAndSaga.errorAction, state => {
            state.fetchStatus.availableToDos = FetchStatus.Error;
        }).addCase(loadAvailableToDosActionAndSaga.resetAction, state => {
            state.fetchStatus.availableToDos = FetchStatus.Default;
        }).addCase(loadAvailableToDosActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.availableToDos = FetchStatus.Success;
            state.availableToDos.elements = {...state.availableToDos.elements, ...normalize(action.payload.elements)};
        }).addCase(loadToDoActionAndSaga.startAction, (state, action) => {
            state.editToDos.fetchStatus[action.payload.id] = FetchStatus.Active;
        }).addCase(loadToDoActionAndSaga.successAction, (state, action) => {
            state.editToDos.elements[action.payload.id] = {
                ...action.payload,
                activeFileUploads: {},
                errorFileUploads: {},
                finishedFileUploads: {},
                taskFetchStatus: {},
                taskLastUserChanged: {},
                taskLastSyncStarted: {},
                fetchStatus: {},
                inMemoryImages: {}
            };
            state.editToDos.fetchStatus[action.payload.id] = FetchStatus.Success;
        }).addCase(loadToDoActionAndSaga.errorAction, (state, action) => {
            state.editToDos.fetchStatus[action.payload.id] = FetchStatus.Error;
        }).addCase(loadToDoActionAndSaga.resetAction, (state, action) => {
            state.editToDos.fetchStatus[action.payload.id] = FetchStatus.Default;
        }).addCase(reloadToDoActionAndSaga.startAction, (state, action) => {
            state.editToDos.fetchStatus[action.payload.id] = FetchStatus.Active;
        }).addCase(reloadToDoActionAndSaga.successAction, (state, action) => {
            const existing = state.editToDos.elements[action.payload.id];
            if (existing) {
                // Create a copy of the answers of the existing to do
                const answers = cloneDeep(existing.answers);
                // Merge the received to do with the existing one
                state.editToDos.elements[action.payload.id] = {
                    ...existing,
                    ...action.payload
                };
                // Merge the answers
                answers.forEach(it => {
                    let existingAnswer = existing!.answers?.find(answer => answer.questionID === it.questionID);
                    if (it.lastUpdatedAnswerTime && existingAnswer?.lastUpdatedAnswerServerTime) {
                        // Compare the timestamps
                        const ownTimestamp = DateTime.fromFormat(it.lastUpdatedAnswerTime, 'yyyy-MM-dd HH:mm:ss');
                        const serverTimestamp = DateTime.fromFormat(existingAnswer.lastUpdatedAnswerServerTime, 'yyyy-MM-dd HH:mm:ss');
                        if (ownTimestamp > serverTimestamp) {
                            // Replace the answer in the to do with the existing answer
                            existingAnswer = it;
                        }
                    } else if (!existingAnswer) {
                        // No answer from server yet, so push own answer into answer array (can happen when sync happens during text input which is not synced yet)
                        existing!.answers.push(it);
                    }
                })
                state.editToDos.fetchStatus[action.payload.id] = FetchStatus.Success;
            }
        }).addCase(reloadToDoActionAndSaga.errorAction, (state, action) => {
            state.editToDos.fetchStatus[action.payload.id] = FetchStatus.Error;
        }).addCase(reloadToDoActionAndSaga.resetAction, (state, action) => {
            state.editToDos.fetchStatus[action.payload.id] = FetchStatus.Default;
        }).addCase(uploadOperationsFilesAction.startAction, (state, action) => {
            action.payload.files.forEach(it => {
                if (!state.editToDos.elements[action.payload.toDoId]!.activeFileUploads[action.payload.questionId]) {
                    state.editToDos.elements[action.payload.toDoId]!.activeFileUploads[action.payload.questionId] = {};
                }
                // Check if this upload has been started from a previously failed upload, then delete the error reference
                if (state.editToDos.elements[action.payload.toDoId]?.errorFileUploads[action.payload.questionId]?.[it.id]) {
                    delete state.editToDos.elements[action.payload.toDoId]!.errorFileUploads[action.payload.questionId]![it.id];
                }
                state.editToDos.elements[action.payload.toDoId]!.activeFileUploads[action.payload.questionId]![it.id] = it;
            });
        }).addCase(uploadOperationsFilesAction.errorAction, (state, action) => {
            action.payload.files.forEach(it => {
                if (!state.editToDos.elements[action.payload.toDoId]!.errorFileUploads[action.payload.questionId]) {
                    state.editToDos.elements[action.payload.toDoId]!.errorFileUploads[action.payload.questionId] = {};
                }
                state.editToDos.elements[action.payload.toDoId]!.errorFileUploads[action.payload.questionId]![it.id] = it;
            });
        }).addCase(uploadOperationsFilesAction.successAction, (state, action) => {
            // Set the file upload to finished
            state.editToDos.elements[action.payload.toDoId]!.activeFileUploads[action.payload.questionId]![action.payload.fileId]!.finished = true;
            if (!state.editToDos.elements[action.payload.toDoId]!.finishedFileUploads) {
                state.editToDos.elements[action.payload.toDoId]!.finishedFileUploads = {};
            }
            if (!state.editToDos.elements[action.payload.toDoId]!.finishedFileUploads[action.payload.questionId]) {
                state.editToDos.elements[action.payload.toDoId]!.finishedFileUploads[action.payload.questionId] = {};
            }
            if ([AttachmentType.Image, AttachmentType.Video].includes(action.payload.file.type)) {
                state.editToDos.elements[action.payload.toDoId]!.inMemoryImages[action.payload.file.path] = action.payload.uploadedFile;
            }
            // If uploaded file is an image, store it in state to enable faster image preview
            state.editToDos.elements[action.payload.toDoId]!.finishedFileUploads[action.payload.questionId]![action.payload.fileId] = action.payload.file;
        }).addCase(setOperationsTaskAnswer(), (state, action) => {
            state.editToDos.elements[action.payload.toDoId]!.answers = state.editToDos.elements[action.payload.toDoId]!.answers.filter(it => it.questionID !== action.payload.questionId);
            if (action.payload.answer) {
                state.editToDos.elements[action.payload.toDoId]!.answers.push(action.payload.answer);
            }
        }).addCase(syncOperationsToDoAction.startAction, (state, action) => {
            state.editToDos.elements[action.payload.toDoId]!.taskFetchStatus[action.payload.questionId] = FetchStatus.Active;
            // Set the timestamp when the last sync has started.
            state.editToDos.elements[action.payload.toDoId]!.taskLastSyncStarted[action.payload.questionId] = action.payload.timestamp;
        }).addCase(syncOperationsToDoAction.successAction, (state, action) => {
            state.editToDos.elements[action.payload.toDoId]!.taskFetchStatus[action.payload.questionId] = FetchStatus.Success;
            state.editToDos.elements[action.payload.toDoId]!.questionState[action.payload.questionId] = action.payload.complete;
            const answer = state.editToDos.elements[action.payload.toDoId]!.answers.find(it => it.questionID === action.payload.questionId);
            // Update the last updated server answer timestamp.
            if (answer) {
                answer.lastUpdatedAnswerServerTime = action.payload.serverTimestamp;
            }
        }).addCase(syncOperationsToDoAction.errorAction, (state, action) => {
            state.editToDos.elements[action.payload.toDoId]!.taskFetchStatus[action.payload.questionId] = FetchStatus.Error;
        }).addCase(syncOperationsToDoAction.resetAction, (state, action) => {
            state.editToDos.elements[action.payload.toDoId]!.taskFetchStatus[action.payload.questionId] = FetchStatus.Default;
        }).addCase(finishToDoActionAndSaga.startAction, (state, action) => {
            state.editToDos.elements[action.payload.toDoId]!.fetchStatus.finish = FetchStatus.Active;
        }).addCase(finishToDoActionAndSaga.errorAction, (state, action) => {
            state.editToDos.elements[action.payload.toDoId]!.fetchStatus.finish = FetchStatus.Error;
        }).addCase(finishToDoActionAndSaga.successAction, (state, action) => {
            state.editToDos.elements[action.payload.toDoId]!.fetchStatus.finish = FetchStatus.Success;
        }).addCase(finishToDoActionAndSaga.resetAction, (state, action) => {
            state.editToDos.elements[action.payload.toDoId]!.fetchStatus.finish = FetchStatus.Default;
        }).addCase(deleteOperationsFileActionAndSaga.startAction, (state, action) => {
            setDeleteFileFetchStatus(state, action, FetchStatus.Active);
        }).addCase(deleteOperationsFileActionAndSaga.successAction, (state, action) => {
            setDeleteFileFetchStatus(state, action, FetchStatus.Success);
        }).addCase(deleteOperationsFileActionAndSaga.errorAction, (state, action) => {
            setDeleteFileFetchStatus(state, action, FetchStatus.Error);
        }).addCase(loadReviewToDosAction.startAction, state => {
            state.reviewToDos.fetchStatus = FetchStatus.Active;
        }).addCase(loadReviewToDosAction.errorAction, state => {
            state.reviewToDos.fetchStatus = FetchStatus.Error;
        }).addCase(loadReviewToDosAction.resetAction, state => {
            state.reviewToDos.fetchStatus = FetchStatus.Default;
        }).addCase(loadReviewToDosAction.successAction, (state, action) => {
            state.reviewToDos.fetchStatus = FetchStatus.Success;
            state.reviewToDos.elements = {...state.reviewToDos.elements, ...normalize(action.payload.elements)};
            state.reviewToDos.numberOfElements = action.payload.totalCount!;
            state.reviewToDos.visible = action.payload.elements.map(it => it.id.toString(10));
        }).addCase(loadUserReviewToDosAction.startAction, (state, action) => {
            state.userReviewToDos.fetchStatus = FetchStatus.Active;
            if (action.payload.replace) {
                state.userReviewToDos.visible = [];
            }
        }).addCase(loadUserReviewToDosAction.errorAction, state => {
            state.userReviewToDos.fetchStatus = FetchStatus.Error;
        }).addCase(loadUserReviewToDosAction.resetAction, state => {
            state.userReviewToDos.fetchStatus = FetchStatus.Default;
        }).addCase(loadUserReviewToDosAction.successAction, (state, action) => {
            state.userReviewToDos.fetchStatus = FetchStatus.Success;
            state.userReviewToDos.elements = {...state.userReviewToDos.elements, ...normalize(action.payload.elements)};
            state.userReviewToDos.numberOfElements = action.payload.totalCount!;
            state.userReviewToDos.visible = action.payload.elements.map(it => it.id.toString(10));
        }).addCase(loadArchiveToDoActionAndSaga.startAction, (state, action) => {
            state.archiveToDos.fetchStatus[action.payload.id] = FetchStatus.Active;
        }).addCase(loadArchiveToDoActionAndSaga.successAction, (state, action) => {
            state.archiveToDos.elements[action.payload.id] = {
                ...action.payload
            };
            state.archiveToDos.fetchStatus[action.payload.id] = FetchStatus.Success;
        }).addCase(loadArchiveToDoActionAndSaga.errorAction, (state, action) => {
            state.archiveToDos.fetchStatus[action.payload.id] = FetchStatus.Error;
        }).addCase(loadArchiveToDoActionAndSaga.resetAction, (state, action) => {
            state.archiveToDos.fetchStatus[action.payload.id] = FetchStatus.Default;
        }).addCase(loadToDoReviewActionAndSaga.startAction, (state, action) => {
            state.reviews.fetchStatus[action.payload.id] = FetchStatus.Active;
        }).addCase(loadToDoReviewActionAndSaga.successAction, (state, action) => {
            state.reviews.elements[action.payload.review.toDoId] = {
                ...action.payload.review
            };
            if (action.payload.declineConfiguration) {
                state.reviews.declineConfigurations[action.payload.review.toDoId] = action.payload.declineConfiguration;
            }
            if (action.payload.approvalConfiguration) {
                state.reviews.approvalConfigurations[action.payload.review.toDoId] = action.payload.approvalConfiguration;
            }
            state.reviews.fetchStatus[action.payload.review.toDoId] = FetchStatus.Success;
        }).addCase(loadToDoReviewActionAndSaga.errorAction, (state, action) => {
            state.reviews.fetchStatus[action.payload.id] = FetchStatus.Error;
        }).addCase(loadToDoReviewActionAndSaga.resetAction, (state, action) => {
            state.reviews.fetchStatus[action.payload.id] = FetchStatus.Default;
        }).addCase(saveToDoReviewRemarkAction.startAction, (state, action) => {
            const remark = state.reviews.elements[action.payload.toDoId]?.remarks.find(it => it.taskId === action.payload.remark.taskId);
            if (remark) {
                remark.fetchStatus = FetchStatus.Active;
            }
        }).addCase(saveToDoReviewRemarkAction.successAction, (state, action) => {
            const remark = state.reviews.elements[action.payload.toDoId]?.remarks.find(it => it.taskId === action.payload.taskId);
            if (remark) {
                remark.fetchStatus = FetchStatus.Success;
                remark.updatedAt = action.payload.updatedAt;
                // Ensure id is set correctly if remark was created in backend
                remark.id = action.payload.id;
            }
        }).addCase(saveToDoReviewRemarkAction.errorAction, (state, action) => {
            const remark = state.reviews.elements[action.payload.toDoId]?.remarks.find(it => it.taskId === action.payload.remark.taskId);
            if (remark) {
                remark.fetchStatus = FetchStatus.Error;
            }
        }).addCase(finishToDoReviewActionAndSaga.startAction, (state, action) => {
            const review = state.reviews.elements[action.payload.toDoId];
            if (review) {
                review.finishFetchStatus = FetchStatus.Active;
            }
        }).addCase(finishToDoReviewActionAndSaga.successAction, (state, action) => {
            state.reviews.elements[action.payload.toDoId] = action.payload;
            state.reviews.elements[action.payload.toDoId]!.finishFetchStatus = FetchStatus.Success;
        }).addCase(finishToDoReviewActionAndSaga.errorAction, (state, action) => {
            const review = state.reviews.elements[action.payload.toDoId];
            if (review) {
                review.finishFetchStatus = FetchStatus.Error;
            }
        }).addCase(finishToDoReviewActionAndSaga.resetAction, (state, action) => {
            const review = state.reviews.elements[action.payload.toDoId];
            if (review) {
                review.finishFetchStatus = FetchStatus.Default;
            }
        }).addCase(loadSubordinatedToDosAction.startAction, (state, action) => {
            state.subordinatedToDos.fetchStatus = FetchStatus.Active;
            if (action.payload.replace) {
                state.subordinatedToDos.visible = [];
            }
        }).addCase(loadSubordinatedToDosAction.errorAction, state => {
            state.subordinatedToDos.fetchStatus = FetchStatus.Error;
        }).addCase(loadSubordinatedToDosAction.resetAction, state => {
            state.subordinatedToDos.fetchStatus = FetchStatus.Default;
        }).addCase(loadSubordinatedToDosAction.successAction, (state, action) => {
            state.subordinatedToDos.fetchStatus = FetchStatus.Success;
            state.subordinatedToDos.elements = {...state.subordinatedToDos.elements, ...normalize(action.payload.elements)};
            state.subordinatedToDos.numberOfElements = action.payload.totalCount!;
            state.subordinatedToDos.visible = [...state.subordinatedToDos.visible, ...action.payload.elements.map(it => it.id.toString(10)).filter(it => !state.subordinatedToDos.visible.includes(it))];
        }).addCase(changeToDoDeadlineActionAndSaga.startAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.ids, FetchStatus.Active, action.payload.statePart, 'changeDeadlineFetchStatus');
        }).addCase(changeToDoDeadlineActionAndSaga.errorAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.ids, FetchStatus.Error, action.payload.statePart, 'changeDeadlineFetchStatus');
        }).addCase(changeToDoDeadlineActionAndSaga.resetAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.ids, FetchStatus.Default, action.payload.statePart, 'changeDeadlineFetchStatus');
        }).addCase(saveOperationsToDoReviewerActionAndSaga.startAction, (state, action) => {
            setReducedToDoFetchStatus(state, [action.payload.toDoId], FetchStatus.Active, action.payload.statePart, 'editReviewerFetchStatus');
        }).addCase(saveOperationsToDoReviewerActionAndSaga.errorAction, (state, action) => {
            setReducedToDoFetchStatus(state, [action.payload.toDoId], FetchStatus.Error, action.payload.statePart, 'editReviewerFetchStatus');
        }).addCase(saveOperationsToDoReviewerActionAndSaga.resetAction, (state, action) => {
            setReducedToDoFetchStatus(state, [action.payload.toDoId], FetchStatus.Default, action.payload.statePart, 'editReviewerFetchStatus');
        }).addCase(loadSubordinatedUserReviewToDosAction.startAction, (state, action) => {
            state.subordinatedUserReviewToDos.fetchStatus = FetchStatus.Active;
            if (action.payload.replace) {
                state.subordinatedUserReviewToDos.visible = [];
            }
        }).addCase(loadSubordinatedUserReviewToDosAction.errorAction, state => {
            state.subordinatedUserReviewToDos.fetchStatus = FetchStatus.Error;
        }).addCase(loadSubordinatedUserReviewToDosAction.resetAction, state => {
            state.subordinatedUserReviewToDos.fetchStatus = FetchStatus.Default;
        }).addCase(loadSubordinatedUserReviewToDosAction.successAction, (state, action) => {
            state.subordinatedUserReviewToDos.fetchStatus = FetchStatus.Success;
            state.subordinatedUserReviewToDos.elements = {...state.subordinatedUserReviewToDos.elements, ...normalize(action.payload.elements)};
            state.subordinatedUserReviewToDos.numberOfElements = action.payload.totalCount!;
            state.subordinatedUserReviewToDos.visible = [...state.subordinatedUserReviewToDos.visible, ...action.payload.elements.map(it => it.id.toString(10)).filter(it => !state.subordinatedUserReviewToDos.visible.includes(it))];
        }).addCase(changeReviewDeadlineActionAndSaga.startAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.reviews.map(it => it.toDoId), FetchStatus.Active, action.payload.statePart, 'changeReviewDeadlineFetchStatus');
        }).addCase(changeReviewDeadlineActionAndSaga.errorAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.reviews.map(it => it.toDoId), FetchStatus.Error, action.payload.statePart, 'changeReviewDeadlineFetchStatus');
        }).addCase(changeReviewDeadlineActionAndSaga.resetAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.reviews.map(it => it.toDoId), FetchStatus.Default, action.payload.statePart, 'changeReviewDeadlineFetchStatus');
        }).addCase(reOpenToDoActionAndSaga.startAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.ids, FetchStatus.Active, 'finishedToDos', 'reOpenFetchStatus');
        }).addCase(reOpenToDoActionAndSaga.errorAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.ids, FetchStatus.Error, 'finishedToDos', 'reOpenFetchStatus');
        }).addCase(reOpenToDoActionAndSaga.resetAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.ids, FetchStatus.Default, 'finishedToDos', 'reOpenFetchStatus');
        }).addCase(reOpenToDoActionAndSaga.successAction, (state, action) => {
            setReducedToDoFetchStatus(state, action.payload.map(it => it.id), FetchStatus.Success, 'finishedToDos', 'reOpenFetchStatus');
        }).addCase(loadFinishedToDoReviewActionAndSaga.startAction, (state, action) => {
            state.reviews.fetchStatus[action.payload.id] = FetchStatus.Active;
        }).addCase(loadFinishedToDoReviewActionAndSaga.successAction, (state, action) => {
            state.reviews.elements[action.payload.review.toDoId] = {
                ...action.payload.review
            };
            state.reviews.fetchStatus[action.payload.review.toDoId] = FetchStatus.Success;
        }).addCase(loadFinishedToDoReviewActionAndSaga.errorAction, (state, action) => {
            state.reviews.fetchStatus[action.payload.id] = FetchStatus.Error;
        }).addCase(loadFinishedToDoReviewActionAndSaga.resetAction, (state, action) => {
            state.reviews.fetchStatus[action.payload.id] = FetchStatus.Default;
        }).addCase(reOpenReviewActionAndSaga.startAction, (state, action) => {
            const review = state.reviews.elements[action.payload.id];
            if (review) {
                review.reOpenFetchStatus = FetchStatus.Active;
            }
        }).addCase(reOpenReviewActionAndSaga.successAction, (state, action) => {
            const review = state.reviews.elements[action.payload.toDoId];
            if (review) {
                review.reOpenFetchStatus = FetchStatus.Active;
            }
        }).addCase(reOpenReviewActionAndSaga.errorAction, (state, action) => {
            const review = state.reviews.elements[action.payload.id];
            if (review) {
                review.reOpenFetchStatus = FetchStatus.Error;
            }
        }).addCase(reOpenReviewActionAndSaga.resetAction, (state, action) => {
            const review = state.reviews.elements[action.payload.id];
            if (review) {
                review.reOpenFetchStatus = FetchStatus.Default;
            }
        }).addCase(loadOpenToDosForToDoDefinitionAndLocationActionAndSaga.startAction, (state, action) => {
            state.fetchStatus.openToDosByToDoDefinitionAndLocation = FetchStatus.Active;
        }).addCase(loadOpenToDosForToDoDefinitionAndLocationActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.openToDosByToDoDefinitionAndLocation = FetchStatus.Success;
            // Add elements to state
            action.payload.elements.forEach(it => state.overview.elements[it.id] = it);
        }).addCase(loadOpenToDosForToDoDefinitionAndLocationActionAndSaga.errorAction, (state, action) => {
            state.fetchStatus.openToDosByToDoDefinitionAndLocation = FetchStatus.Error;
        }).addCase(loadOpenToDosForToDoDefinitionAndLocationActionAndSaga.resetAction, (state, action) => {
            state.fetchStatus.openToDosByToDoDefinitionAndLocation = FetchStatus.Default;
        }).addCase(loadPreviousToDosForToDoActionAndSaga.startAction, (state, action) => {
            state.previousToDos.fetchStatus[action.payload.toDoId] = FetchStatus.Active;
        }).addCase(loadPreviousToDosForToDoActionAndSaga.errorAction, (state, action) => {
            state.previousToDos.fetchStatus[action.payload.toDoId] = FetchStatus.Error;
        }).addCase(loadPreviousToDosForToDoActionAndSaga.resetAction, (state, action) => {
            state.previousToDos.fetchStatus[action.payload.toDoId] = FetchStatus.Default;
        }).addCase(declineToDoReviewActionAndSaga.startAction, (state, action) => {
            state.reviews.declineFetchStatus[action.payload.toDoId] = FetchStatus.Active;
        }).addCase(declineToDoReviewActionAndSaga.errorAction, (state, action) => {
            state.reviews.declineFetchStatus[action.payload.toDoId] = FetchStatus.Error;
        }).addCase(declineToDoReviewActionAndSaga.resetAction, (state, action) => {
            state.reviews.declineFetchStatus[action.payload.toDoId] = FetchStatus.Default;
        }).addCase(preFinishToDoActionAndSaga.startAction, (state, action) => {
            state.editToDos.elements[action.payload.id]!.fetchStatus.preFinish = FetchStatus.Active;
            // Reset the pre finish results.
            state.editToDos.elements[action.payload.id]!.preFinishResults = [];
        }).addCase(preFinishToDoActionAndSaga.errorAction, (state, action) => {
            state.editToDos.elements[action.payload.id]!.fetchStatus.preFinish = FetchStatus.Error;
        }).addCase(preFinishToDoActionAndSaga.resetAction, (state, action) => {
            state.editToDos.elements[action.payload.id]!.fetchStatus.preFinish = FetchStatus.Default;
        }).addCase(finishAutoApproveToDoActionAndSaga.startAction, (state, action) => {
            state.editToDos.elements[action.payload.id]!.fetchStatus.autoApprove = FetchStatus.Active;
        }).addCase(finishAutoApproveToDoActionAndSaga.errorAction, (state, action) => {
            state.editToDos.elements[action.payload.id]!.fetchStatus.autoApprove = FetchStatus.Error;
        }).addCase(finishAutoApproveToDoActionAndSaga.successAction, (state, action) => {
            state.editToDos.elements[action.payload.toDoId]!.fetchStatus.autoApprove = FetchStatus.Success;
        }).addCase(finishAutoApproveToDoActionAndSaga.resetAction, (state, action) => {
            state.editToDos.elements[action.payload.id]!.fetchStatus.autoApprove = FetchStatus.Default;
        }).addCase(finishAutoDeclineToDoActionAndSaga.startAction, (state, action) => {
            state.editToDos.elements[action.payload.id]!.fetchStatus.autoDecline = FetchStatus.Active;
        }).addCase(finishAutoDeclineToDoActionAndSaga.errorAction, (state, action) => {
            state.editToDos.elements[action.payload.id]!.fetchStatus.autoDecline = FetchStatus.Error;
        }).addCase(finishAutoDeclineToDoActionAndSaga.successAction, (state, action) => {
            state.editToDos.elements[action.payload.toDoId]!.fetchStatus.autoDecline = FetchStatus.Success;
        }).addCase(finishAutoDeclineToDoActionAndSaga.resetAction, (state, action) => {
            state.editToDos.elements[action.payload.id]!.fetchStatus.autoDecline = FetchStatus.Default;
        }).addCase(loadRequestsToDosAction.startAction, (state, action) => {
            state.requestOverview.fetchStatus = FetchStatus.Active;
            if (action.payload.replace) {
                state.requestOverview.visible = [];
            }
        }).addCase(loadRequestsToDosAction.errorAction, state => {
            state.requestOverview.fetchStatus = FetchStatus.Error;
        }).addCase(loadRequestsToDosAction.resetAction, state => {
            state.requestOverview.fetchStatus = FetchStatus.Default;
        }).addCase(loadRequestsToDosAction.successAction, (state, action) => {
            state.requestOverview.fetchStatus = FetchStatus.Success;
            state.requestOverview.elements = {...state.requestOverview.elements, ...normalize(action.payload.elements)};
            state.requestOverview.numberOfElements = action.payload.totalCount!;
            state.requestOverview.visible = [...state.requestOverview.visible, ...action.payload.elements.map(it => it.id.toString(10)).filter(it => !state.requestOverview.visible.includes(it))];
        }).addCase(loadApprovedRequestsAction.startAction, (state, action) => {
            state.approvedRequests.fetchStatus = FetchStatus.Active;
            if (action.payload.replace) {
                state.approvedRequests.visible = [];
            }
        }).addCase(loadApprovedRequestsAction.errorAction, state => {
            state.approvedRequests.fetchStatus = FetchStatus.Error;
        }).addCase(loadApprovedRequestsAction.resetAction, state => {
            state.approvedRequests.fetchStatus = FetchStatus.Default;
        }).addCase(loadApprovedRequestsAction.successAction, (state, action) => {
            state.approvedRequests.fetchStatus = FetchStatus.Success;
            state.approvedRequests.elements = {...state.approvedRequests.elements, ...normalize(action.payload.elements)};
            state.approvedRequests.numberOfElements = action.payload.totalCount!;
            state.approvedRequests.visible = [...state.approvedRequests.visible, ...action.payload.elements.map(it => it.id.toString(10)).filter(it => !state.approvedRequests.visible.includes(it))];
        }).addCase(loadDeclinedRequestsAction.startAction, (state, action) => {
            state.declinedRequests.fetchStatus = FetchStatus.Active;
            if (action.payload.replace) {
                state.declinedRequests.visible = [];
            }
        }).addCase(loadDeclinedRequestsAction.errorAction, state => {
            state.declinedRequests.fetchStatus = FetchStatus.Error;
        }).addCase(loadDeclinedRequestsAction.resetAction, state => {
            state.declinedRequests.fetchStatus = FetchStatus.Default;
        }).addCase(loadDeclinedRequestsAction.successAction, (state, action) => {
            state.declinedRequests.fetchStatus = FetchStatus.Success;
            state.declinedRequests.elements = {...state.declinedRequests.elements, ...normalize(action.payload.elements)};
            state.declinedRequests.numberOfElements = action.payload.totalCount!;
            state.declinedRequests.visible = [...state.declinedRequests.visible, ...action.payload.elements.map(it => it.id.toString(10)).filter(it => !state.declinedRequests.visible.includes(it))];
        }).addCase(loadExpiredRequestsAction.startAction, (state, action) => {
            state.expiredRequests.fetchStatus = FetchStatus.Active;
            if (action.payload.replace) {
                state.expiredRequests.visible = [];
            }
        }).addCase(loadExpiredRequestsAction.errorAction, state => {
            state.expiredRequests.fetchStatus = FetchStatus.Error;
        }).addCase(loadExpiredRequestsAction.resetAction, state => {
            state.expiredRequests.fetchStatus = FetchStatus.Default;
        }).addCase(loadExpiredRequestsAction.successAction, (state, action) => {
            state.expiredRequests.fetchStatus = FetchStatus.Success;
            state.expiredRequests.elements = {...state.expiredRequests.elements, ...normalize(action.payload.elements)};
            state.expiredRequests.numberOfElements = action.payload.totalCount!;
            state.expiredRequests.visible = [...state.expiredRequests.visible, ...action.payload.elements.map(it => it.id.toString(10)).filter(it => !state.expiredRequests.visible.includes(it))];
        }).addCase(loadUserReviewRequestsAction.startAction, (state, action) => {
            state.userReviewRequests.fetchStatus = FetchStatus.Active;
            if (action.payload.replace) {
                state.userReviewToDos.visible = [];
            }
        }).addCase(loadUserReviewRequestsAction.errorAction, state => {
            state.userReviewRequests.fetchStatus = FetchStatus.Error;
        }).addCase(loadUserReviewRequestsAction.resetAction, state => {
            state.userReviewRequests.fetchStatus = FetchStatus.Default;
        }).addCase(loadUserReviewRequestsAction.successAction, (state, action) => {
            state.userReviewRequests.fetchStatus = FetchStatus.Success;
            state.userReviewRequests.elements = {...state.userReviewToDos.elements, ...normalize(action.payload.elements)};
            state.userReviewRequests.numberOfElements = action.payload.totalCount!;
            state.userReviewRequests.visible = action.payload.elements.map(it => it.id.toString(10));
        }).addCase(loadSubordinatedUserReviewRequestsAction.startAction, (state, action) => {
            state.subordinatedUserReviewRequests.fetchStatus = FetchStatus.Active;
            if (action.payload.replace) {
                state.subordinatedUserReviewRequests.visible = [];
            }
        }).addCase(loadSubordinatedUserReviewRequestsAction.errorAction, state => {
            state.subordinatedUserReviewRequests.fetchStatus = FetchStatus.Error;
        }).addCase(loadSubordinatedUserReviewRequestsAction.resetAction, state => {
            state.subordinatedUserReviewRequests.fetchStatus = FetchStatus.Default;
        }).addCase(loadSubordinatedUserReviewRequestsAction.successAction, (state, action) => {
            state.subordinatedUserReviewRequests.fetchStatus = FetchStatus.Success;
            state.subordinatedUserReviewRequests.elements = {...state.subordinatedUserReviewRequests.elements, ...normalize(action.payload.elements)};
            state.subordinatedUserReviewRequests.numberOfElements = action.payload.totalCount!;
            state.subordinatedUserReviewRequests.visible = [...state.subordinatedUserReviewRequests.visible, ...action.payload.elements.map(it => it.id.toString(10)).filter(it => !state.subordinatedUserReviewRequests.visible.includes(it))];
        }).addCase(loadReviewRequestsAction.startAction, (state, action) => {
            state.reviewRequests.fetchStatus = FetchStatus.Active;
            if (action.payload.replace) {
                state.reviewRequests.visible = [];
            }
        }).addCase(loadReviewRequestsAction.errorAction, state => {
            state.reviewRequests.fetchStatus = FetchStatus.Error;
        }).addCase(loadReviewRequestsAction.resetAction, state => {
            state.reviewRequests.fetchStatus = FetchStatus.Default;
        }).addCase(loadReviewRequestsAction.successAction, (state, action) => {
            state.reviewRequests.fetchStatus = FetchStatus.Success;
            state.reviewRequests.elements = {...state.reviewRequests.elements, ...normalize(action.payload.elements)};
            state.reviewRequests.numberOfElements = action.payload.totalCount!;
            state.reviewRequests.visible = [...state.reviewRequests.visible, ...action.payload.elements.map(it => it.id.toString(10)).filter(it => !state.reviewRequests.visible.includes(it))];
        })
});

const setDeleteFileFetchStatus = (state: OperationsState, action: PayloadAction<DeleteOperationsFilePayload>, fetchStatus: FetchStatus) => {
    // Get file from state (add optional operator to avoid exceptions if answer or file is already deleted)
    const file = (state.editToDos.elements[action.payload.toDoId]?.answers
        .find(it => it.questionID === action.payload.questionId) as FileUploadAnswer | undefined)
        ?.files?.find(it => it.path === action.payload.path);
    if (file) {
        file.deleteFetchStatus = fetchStatus;
    }
}

export const {
    setOperationsFinishedToDosSearch,
    setOperationsFinishedToDosToDoIds,
    setOperationsFinishedToDosPage,
    setOperationsFinishedToDosPageAndPageSize,
    setOperationsPendingToDosPage,
    setOperationsPendingToDosToDoIds,
    setOperationsFinishedToDosLocationIds,
    setOperationsPendingToDosSearch,
    setOperationsPendingToDosLocationIds,
    deleteFailedOperationsFileUpload,
    moveToDoToFinishedToDo,
    moveToDoToRequests,
    setOperationsEditEditor,
    resetOperationsEditEditors,
    setOperationsFileUploadProgress,
    deleteFinishedOperationsFileUploads,
    deleteOperationsFileFromAnswer,
    setToDoLastUpdatedTimestamp,
    setTaskLastUserChanged,
    setOperationsReviewRemark,
    deleteReviewToDo,
    moveToDosFromSubordinatedToOwnToDos,
    updateToDoDeadlines,
    setOperationsUserReviewToDosPageAndPageSize,
    setOperationsSubordinatedToDosPageAndPageSize,
    setOperationsSubordinatedUserReviewToDosPageAndPageSize,
    setOperationsReviewToDosSearch,
    setOperationsUserReviewToDosSearch,
    setOperationsSubordinatedToDosSearch,
    setOperationsSubordinatedUserReviewToDosSearch,
    setOperationsSubordinatedToDosLocationIds,
    setOperationsSubordinatedUserReviewToDosLocationIds,
    setOperationsUserReviewToDosLocationIds,
    setOperationsReviewToDosLocationIds,
    setOperationsSubordinatedToDosToDoIds,
    setOperationsSubordinatedUserReviewToDosToDoIds,
    setOperationsUserReviewToDosToDoIds,
    setOperationsReviewToDosToDoIds,
    setOperationsSaveReviewerSuccess,
    setOperationsRequestsPage,
    setOperationsRequestsLocationIds,
    setOperationsRequestsToDosSearch,
    setOperationsRequestsToDoIds,
    setOperationsApprovedRequestsSearch,
    setOperationsApprovedRequestsToDoIds,
    setOperationsApprovedRequestsLocationIds,
    setOperationsApprovedRequestsPageAndPageSize,
    setOperationsDeclinedRequestsSearch,
    setOperationsDeclinedRequestsToDoIds,
    setOperationsDeclinedRequestsLocationIds,
    setOperationsDeclinedRequestsPageAndPageSize,
    setOperationsExpiredRequestsSearch,
    setOperationsExpiredRequestsToDoIds,
    setOperationsExpiredRequestsLocationIds,
    setOperationsExpiredRequestsPageAndPageSize,
    setOperationsUserReviewRequestsToDoIds,
    setOperationsUserReviewRequestsSearch,
    setOperationsUserReviewRequestsLocationIds,
    setOperationsUserReviewRequestsPageAndPageSize,
    setOperationsSubordinatedUserReviewRequestsSearch,
    setOperationsSubordinatedUserReviewRequestsLocationIds,
    setOperationsSubordinatedUserReviewRequestsToDoIds,
    setOperationsSubordinatedUserReviewRequestsPageAndPageSize,
    setOperationsReviewRequestsToDoIds,
    setOperationsReviewRequestsLocationIds,
    setOperationsReviewRequestsPageAndPageSize,
    setOperationsReviewRequestsSearch,
    updateReviewDeadlines,
    resetToDoSearch,
    resetStatePartFetchStatus,
    removeToDosFromFinishedToDos,
    disapproveReviewedTask,
    addPreviousToDosToState,
    declineToDoReviewSuccess,
    setToDoPreFinishSuccess,
    setToDoReviewers,
} = operations.actions;

export default operations.reducer;