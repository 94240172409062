import {PaginationState} from '../../../types/Types';
import {FetchStatus, UploadFile} from '@software/reactcommons';
import {AttachmentType, ImagePath} from '../../../types/charts/chartTypes';
import {TypographyVariant} from '@mui/material';
import {CompiledMessage} from '@software/reactcommons/dist/localization/message/message.types';

export type OperationsStatePart = 'overview' | 'finishedToDos' | 'reviewToDos' | 'subordinatedToDos' | 'userReviewToDos' | 'subordinatedUserReviewToDos' | 'requestOverview' | 'approvedRequests' | 'expiredRequests' | 'declinedRequests' | 'reviewRequests' | 'userReviewRequests' | 'subordinatedUserReviewRequests';

export interface ToDoProgress {
    completedTasks: number;
    numberOfTasks: number;
    numberOfOptionalTasks: number;
}

export enum ToDoInterval {
    Daily = 'DAILY',
    Weekly = 'WEEKLY',
    Monthly = 'MONTHLY',
    Quarterly = 'QUARTERLY',
    HalfYearly = 'HALF_YEARLY',
    Yearly = 'YEARLY',
    Every4Weeks = 'EVERY_4_WEEKS',
    Every6Weeks = 'EVERY_6_WEEKS',
    CustomDays = 'CUSTOM_DAYS',
    OneTime = 'ONE_TIME',
    ByMonthly = 'BY_MONTHLY',
    EveryTwoYears = 'EVERY_TWO_YEARS',
    EveryTwoWeeks = 'EVERY_TWO_WEEKS'
}

export interface Person {
    id: number;
    title: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
}

export interface ToDoReview {
    id: number;
    createdAt: string;
    deadline: string;
    finishedAt?: string;
    finishedBy?: Person;
    json: string;
    remarks: any[];
    reviewers: Person[];
    updatedAt: string;
    autoApproved: boolean;
}

export enum ToDoType {
    Report = 'Report',
    ToDo = 'ToDo',
    Request = 'Request'
}

export enum ToDoArchiveDisplayConfigurationType {
    TaskPreviewConfiguration = 'TaskPreviewConfiguration'
}

export interface ToDoArchiveDisplayConfiguration {
    type: ToDoArchiveDisplayConfigurationType;
}

export interface ToDoArchiveTaskPreviewConfiguration extends ToDoArchiveDisplayConfiguration {
    type: ToDoArchiveDisplayConfigurationType.TaskPreviewConfiguration;
    taskIds: number[];
}

export interface ToDoDTO {
    type: ToDoType;
    autoFinished: boolean;
    autoFinishedDate?: string;
    declinedReviewDate?: string;
    creationDate: string;
    editors: string[];
    finished: boolean;
    finishedDate?: string;
    locationId: number;
    toDoDefinitionId: number;
    firstCategoryId: number;
    id: number;
    restrictedApprovalDate?: string;
    lastPossibleFinishingDate?: string;
    requestedReviewDate?: string;
    manual: boolean;
    name: string;
    numberOfCategories: number;
    userFinished: boolean;
    editEditorFetchStatus?: FetchStatus;
    editReviewerFetchStatus?: FetchStatus;
    changeDeadlineFetchStatus?: FetchStatus;
    changeReviewDeadlineFetchStatus?: FetchStatus;
    reOpenFetchStatus?: FetchStatus;
    downloadFetchStatus?: FetchStatus;
    sendEmailFetchStatus?: FetchStatus;
    progress: ToDoProgress;
    group?: ToDoGroup;
    canBeReOpened: boolean;
    interval: ToDoInterval;
    reviews: ToDoReview[];
    tasks: ToDoTask[];
    answers: TaskAnswer[]
    archiveDisplayConfiguration?: ToDoArchiveDisplayConfiguration;
}

export interface Editor {
    id: number;
    name: string;
    email: string;
}

export interface Questionnaire {
    id: number;
    name: string;
}

export interface ToDoPaginationState extends PaginationState<ToDoDTO> {
    locationIds: number[];
    toDoIds: number[];
}

export interface ToDoGroup {
    id: number;
    name: string;
    backgroundColor?: string;
    textColor?: string;
    order: number;
}

export interface AnswerAttachment {
    type: AttachmentType;
    name: string;
    path: string;
    fileType: string;
    deleteFetchStatus?: FetchStatus;
}

export interface FileAnswerAttachment extends AnswerAttachment {
    type: AttachmentType.File;
}

export interface VideoAnswerAttachment extends AnswerAttachment {
    type: AttachmentType.Video;
}

export interface PdfAnswerAttachment extends AnswerAttachment {
    type: AttachmentType.Pdf;
}

export interface ImageAnswerAttachment extends AnswerAttachment {
    type: AttachmentType.Image;
    is360: boolean;
    paths: ImagePath[];
}

export interface TextInputAnswer extends TaskAnswer {
    comment?: string | null;
}

export interface DefaultAnswer<AnswerType> extends TaskAnswer {
    answer?: AnswerType;
}

export interface DefaultTextAnswer<AnswerType> extends DefaultAnswer<AnswerType>, TextInputAnswer {

}

export interface FileUploadAnswer extends TaskAnswer {
    files?: AnswerAttachment[];
}

export interface DefaultTextFileUploadAnswer<AnswerType> extends DefaultTextAnswer<AnswerType>, FileUploadAnswer {

}

export interface InputTaskAnswer extends TextInputAnswer {

}

export interface InputFileUploadTaskAnswer extends TextInputAnswer, FileUploadAnswer {

}

export interface NumberInputTaskAnswer extends TaskAnswer {
    value?: number;
}

export interface NumberInputTextInputTaskAnswer extends NumberInputTaskAnswer, TextInputAnswer {

}

export interface NumberInputTextInputFileUploadTaskAnswer extends NumberInputTaskAnswer, TextInputAnswer, FileUploadAnswer {

}

export interface SelectTaskAnswer extends DefaultAnswer<number> {

}

export interface SelectTextInputTaskAnswer extends DefaultTextAnswer<number> {

}

export interface SelectTextInputFileUploadTaskAnswer extends DefaultTextFileUploadAnswer<number> {

}

export enum TaskAnswerType {
    Aosr = 'aosr',
    AosrComment = 'aosrComment',
    AosrCommentAttachment = 'aosrCommentAttachment',
    Comment = 'comment',
    Egnhn = 'egnhn',
    EgnhnComment = 'egnhnComment',
    EgnhnCommentAttachment = 'egnhnCommentAttachment',
    Emnu = 'emnu',
    EmnuComment = 'emnuComment',
    EmnuCommentAttachment = 'emnuCommentAttachment',
    FlashLight = 'flashLight',
    FlashLightComment = 'flashLightComment',
    FlashLightCommentAttachment = 'flashLightCommentAttachment',
    Input = 'input',
    InputAttachment = 'inputAttachment',
    NumberInput = 'numberInput',
    NumberInputComment = 'numberInputComment',
    NumberInputCommentAttachment = 'numberInputCommentAttachment',
    Scale5 = 'scale',
    Scale5Comment = 'scaleComment',
    Scale5CommentAttachment = 'scaleCommentAttachment',
    Select = 'select',
    SelectComment = 'selectComment',
    SelectCommentAttachment = 'selectCommentAttachment',
    Semnu = 'semnu',
    SemnuComment = 'semnuComment',
    SemnuCommentAttachment = 'semnuCommentAttachment',
    YesNo = 'yesNo',
    YesNoComment = 'yesNoComment',
    YesNoCommentAttachment = 'yesNoCommentAttachment',
    YesNoNotSpecified = 'yesNoNotSpecified',
    YesNoNotSpecifiedComment = 'yesNoNotSpecifiedComment',
    YesNoNotSpecifiedCommentAttachment = 'yesNoNotSpecifiedCommentAttachment',
    Yna = 'yna',
    YnaComment = 'ynaComment',
    YnaCommentAttachment = 'ynaCommentAttachment',
    FileUpload = 'fileUpload',
    FileUploadComment = 'fileUploadComment',
}

export interface TaskAnswer {
    answerType: TaskAnswerType;
    questionID: number;
    questionVersion: number;
    addressBookID: number;
    topCategoryID: number;
    originalAnswerTime: string;
    lastUpdatedAnswerTime: string;
    lastUpdatedAnswerServerTime?: string;
    version?: number;
    external?: boolean;
}

export interface BooleanTaskAnswer extends TaskAnswer {
    state?: boolean;
}

export interface BooleanTextInputTaskAnswer extends BooleanTaskAnswer, TextInputAnswer {
}

export interface BooleanTextInputFileUploadTaskAnswer extends BooleanTaskAnswer, TextInputAnswer, FileUploadAnswer {
}

export enum BooleanNotSpecifiedValue {
    True = 'YES',
    False = 'NO',
    NotSpecified = 'NOT_SPECIFIED'
}

export interface BooleanNotSpecifiedTaskAnswer extends DefaultAnswer<BooleanNotSpecifiedValue> {
}

export interface BooleanNotSpecifiedTextInputTaskAnswer extends DefaultTextAnswer<BooleanNotSpecifiedValue> {
}

export interface BooleanNotSpecifiedTextInputFileUploadTaskAnswer extends DefaultTextFileUploadAnswer<BooleanNotSpecifiedValue> {
}

export enum EmnuValue {
    ExceedsExecution = 'EXCEEDS_EXECUTION',
    MeetsExecution = 'MEETS_EXECUTION',
    NotExecuted = 'NOT_EXECUTED',
    Unacceptable = 'UNACCEPTABLE'
}

export interface EmnuTaskAnswer extends DefaultAnswer<EmnuValue> {
}

export interface EmnuTextInputTaskAnswer extends DefaultTextAnswer<EmnuValue> {
}

export interface EmnuTextInputFileUploadTaskAnswer extends DefaultTextFileUploadAnswer<EmnuValue> {
}

export enum AosrValue {
    Always = 'ALWAYS',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY'
}

export interface AosrTaskAnswer extends DefaultAnswer<AosrValue> {
}

export interface AosrTextInputTaskAnswer extends DefaultTextAnswer<AosrValue> {
}

export interface AosrTextInputFileUploadTaskAnswer extends DefaultTextFileUploadAnswer<AosrValue> {
}

export enum EgnhnValue {
    Expert = 'EXPERT',
    Good = 'GOOD',
    NeedsHelp = 'NEEDS_HELP',
    NewBehaviour = 'NEW_BEHAVIOUR'
}

export interface EgnhnTaskAnswer extends DefaultAnswer<EgnhnValue> {
}

export interface EgnhnTextInputTaskAnswer extends DefaultTextAnswer<EgnhnValue> {
}

export interface EgnhnTextInputFileUploadTaskAnswer extends DefaultTextFileUploadAnswer<EgnhnValue> {
}

export enum YnaValue {
    Yes = 'YES',
    No = 'NO',
    Addressed = 'ADDRESSED'
}

export interface YnaTaskAnswer extends DefaultAnswer<YnaValue> {
}

export interface YnaTextInputTaskAnswer extends DefaultTextAnswer<YnaValue> {
}

export interface YnaTextInputFileUploadTaskAnswer extends DefaultTextFileUploadAnswer<YnaValue> {
}

export enum FlashLightValue {
    Green = 'GREEN',
    Yellow = 'YELLOW',
    Red = 'RED'
}

export interface FlashLightTaskAnswer extends DefaultAnswer<FlashLightValue> {
}

export interface FlashLightTextInputTaskAnswer extends DefaultTextAnswer<FlashLightValue> {
}

export interface FlashLightTextInputFileUploadTaskAnswer extends DefaultTextFileUploadAnswer<FlashLightValue> {
}

export enum SemnuValue {
    S = 'S',
    ExceedsExecution = 'EXCEEDS_EXECUTION',
    MeetsExecution = 'MEETS_EXECUTION',
    NotExecuted = 'NOT_EXECUTED',
    Unacceptable = 'UNACCEPTABLE'
}

export interface SemnuTaskAnswer extends DefaultAnswer<SemnuValue> {
}

export interface SemnuTextInputTaskAnswer extends DefaultTextAnswer<SemnuValue> {
}

export interface SemnuTextInputFileUploadTaskAnswer extends DefaultTextFileUploadAnswer<SemnuValue> {
}

export interface FileUploadTextInputTaskAnswer extends FileUploadAnswer, TextInputAnswer {

}

export interface Scale5TaskAnswer extends DefaultAnswer<number> {
}

export interface Scale5TextInputTaskAnswer extends DefaultTextAnswer<number> {
}

export interface Scale5TextInputFileUploadTaskAnswer extends DefaultTextFileUploadAnswer<number> {
}


export enum ToDoTaskType {
    YesNo = 'YES_NO',
    YesNoComment = 'YES_NO_COMMENT',
    YesNoCommentFile = 'YES_NO_COMMENT_PHOTO',
    Comment = 'COMMENT',
    YesNoNotSpecified = 'YES_NO_NOT_SPECIFIED',
    YesNoNotSpecifiedComment = 'YES_NO_NOT_SPECIFIED_COMMENT',
    YesNoNotSpecifiedCommentFile = 'YES_NO_NOT_SPECIFIED_COMMENT_PHOTO',
    Emnu = 'EMNU',
    Aosr = 'AOSR',
    Egnhn = 'EGNHN',
    Yna = 'YNA',
    EmnuComment = 'EMNU_COMMENT',
    AosrComment = 'AOSR_COMMENT',
    EgnhnComment = 'EGNHN_COMMENT',
    YnaComment = 'YNA_COMMENT',
    EmnuCommentFile = 'EMNU_COMMENT_PHOTO',
    AosrCommentFile = 'AOSR_COMMENT_PHOTO',
    EgnhnCommentFile = 'EGNHN_COMMENT_PHOTO',
    YnaCommentFile = 'YNA_COMMENT_PHOTO',
    Input = 'INPUT',
    FlashLight = 'FLASH_LIGHT',
    FlashLightComment = 'FLASH_LIGHT_COMMENT',
    FlashLightCommentFile = 'FLASH_LIGHT_COMMENT_PHOTO',
    Number = 'NUMBER',
    Semnu = 'SEMNU',
    SemnuComment = 'SEMNU_COMMENT',
    SemnuCommentFile = 'SEMNU_COMMENT_PHOTO',
    Scale5 = 'SCALE_5',
    Scale5Comment = 'SCALE_5_COMMENT',
    Scale5CommentFile = 'SCALE_5_COMMENT_PHOTO',
    Select = 'SELECT',
    SelectComment = 'SELECT_COMMENT',
    SelectCommentFile = 'SELECT_COMMENT_PHOTO',
    NumberComment = 'NUMBER_COMMENT',
    NumberCommentFile = 'NUMBER_COMMENT_PHOTO',
    InputFile = 'INPUT_PHOTO',
    FileUpload = 'FILE_UPLOAD',
    FileUploadComment = 'FILE_UPLOAD_COMMENT',
}

const FileUploadTaskTypes = [ToDoTaskType.YesNoCommentFile, ToDoTaskType.AosrCommentFile, ToDoTaskType.EgnhnCommentFile,
    ToDoTaskType.EmnuCommentFile, ToDoTaskType.FlashLightCommentFile, ToDoTaskType.NumberCommentFile, ToDoTaskType.FileUpload,
    ToDoTaskType.FileUploadComment, ToDoTaskType.SelectCommentFile, ToDoTaskType.Scale5CommentFile, ToDoTaskType.SemnuCommentFile,
    ToDoTaskType.YnaCommentFile, ToDoTaskType.YesNoNotSpecifiedCommentFile, ToDoTaskType.InputFile];

export const isFileUploadTask = (type: ToDoTaskType) => FileUploadTaskTypes.includes(type);

export interface ToDoTaskAnswer {
    id: number;
    description: string;
}

export enum NumberType {
    Absolute = 'ABSOLUTE',
    Percentage = 'PERCENTAGE',
    Permille = 'PERMILLE',
    Euro = 'EURO'
}

export enum TaskConditionType {
    TaskAnswer = 'taskAnswer',
    And = 'and',
    Or = 'or',
    Not = 'not'
}

export interface TaskCondition {
    type: TaskConditionType;
}

export interface TaskAnswerCondition {
    type: TaskConditionType.TaskAnswer;
    taskId: number;
    values: number[];
}

export interface TaskAndCondition {
    type: TaskConditionType.And;
    conditions: TaskCondition[];
}

export interface TaskOrCondition {
    type: TaskConditionType.Or;
    conditions: TaskCondition[];
}

export interface TaskNotCondition {
    type: TaskConditionType.Not;
    condition: TaskCondition;
}


export enum ToDoTaskConfigurationElementType {
    AttachmentRequired = 'attachmentRequired',
    InputAppendix = 'inputAppendix',
    TaskAppendices = 'taskAppendices',
    Actions = 'actions',
    AttachmentButton = 'attachmentButton',
    BooleanTask = 'booleanTask',
    SelectTask = 'selectTask',
    RequiredTask = 'requiredTask',
    TriggerAddRemark = 'triggerAddRemark',
    TaskDescription = 'taskDescription',
    AttachmentMaxAge = 'attachmentMaxAge',
    DeletableAnswer = 'deletableAnswer',
    ApplyNumberFormatting = 'applyNumberFormatting'
}

export interface ToDoTaskConfiguration {
    elements: ToDoTaskConfigurationElement[];
}

export interface ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType;
}

export interface AttachmentRequiredToDoTaskConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.AttachmentRequired;
    allowedAttachmentMimeTypes?: string[];
    numberOfAttachments: number;
    condition?: TaskCondition;
}

export interface AttachmentButtonToDoTaskConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.AttachmentButton;
    icon?: OperationsIcon;
    text?: string;
}

export interface InputAppendixToDoTaskConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.InputAppendix;
    appendix: string;
}

export enum TaskAppendixType {
    Image = 'Image',
    Video = 'Video',
    Pdf = 'Pdf'
}

export enum Breakpoint {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl'
}

export interface TaskAppendixButtonConfiguration {
    buttonText?: string;
    icon?: OperationsIcon;
}

export interface TaskAppendix {
    path: string;
    title?: string;
    displayRatio?: number;
    filename?: string;
    type: TaskAppendixType;
    showPreview: boolean;
    allowFullScreen: boolean;
    allowDownload: boolean;
    gridColumnWidth?: Record<Breakpoint, number>;
    downloadButtonConfiguration?: TaskAppendixButtonConfiguration;
    fullScreenButtonConfiguration?: TaskAppendixButtonConfiguration;
    reviewSpecification: boolean;
}

export interface TaskAppendicesToDoTaskConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.TaskAppendices;
    appendices: TaskAppendix[];
}


export interface TaskDescriptionToDoTaskConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.TaskDescription;
    description: string;
    variant: TypographyVariant;
}

export interface TaskTriggerAddRemarkConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.TriggerAddRemark;
    triggerRemark: boolean;
}

export enum TaskActionType {
    Mail = 'mail',
    TextOnly = 'textOnly',
    Link = 'link',
    Phone = 'phone',
    DownloadFile = 'downloadFile'
}

export interface TaskActionsToDoTaskConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.Actions;
    actions: TaskAction[];
}

export interface TaskAction {
    type: TaskActionType;
    icon?: OperationsIcon;
    buttonText?: string;
    description?: string;
}

export enum OperationsIcon {
    Mail = 'Mail',
    Camera = 'Camera',
    Attachment = 'Attachment',
    Phone = 'Phone',
    Download = 'Download',
    ZoomIn = 'ZoomIn',
    Web = 'Web'
}

export interface TaskMailAction extends TaskAction {
    type: TaskActionType.Mail;
    to: string;
    cc?: string;
    subject?: string;
    text?: string;
}

export interface TaskPhoneAction extends TaskAction {
    type: TaskActionType.Phone;
    phoneNumber: string;
}

export interface TaskDownloadFileAction extends TaskAction {
    type: TaskActionType.DownloadFile;
    path: string;
    filename: string;
}

export interface TaskLinkAction extends TaskAction {
    type: TaskActionType.Link;
    link: string;
    target?: string;
}

export interface TextOnlyAction extends TaskAction {
    type: TaskActionType.TextOnly;
}

export enum BooleanTaskVisualizationType {
    Buttons = 'Buttons',
    CheckBox = 'Checkbox'
}

export interface BooleanTaskToDoTaskConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.BooleanTask;
    visualizationType: BooleanTaskVisualizationType;
}

export enum SelectTaskVisualizationType {
    SelectBox = 'SelectBox',
    Buttons = 'Buttons'
}

export interface SelectTaskToDoTaskConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.SelectTask;
    visualizationType: SelectTaskVisualizationType;
}

export interface RequiredTaskToDoTaskConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.RequiredTask;
    visibleIfNotRequired: boolean;
    condition: TaskCondition;
}

export interface AttachmentMaxAgeToDoTaskConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.AttachmentMaxAge;
    maxAge: string;
    errorMessage?: string;
}

export interface DeletableAnswerToDoTaskConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.DeletableAnswer;
    deletable: boolean;
}

export interface ApplyNumberFormattingToDoTaskConfigurationElement extends ToDoTaskConfigurationElement {
    type: ToDoTaskConfigurationElementType.ApplyNumberFormatting;
    applyNumberFormatting: boolean;
}


export interface ToDoCategoryPage {
    id: number;
    name: string;
    categoryIds: number[];
}

export interface ToDoTaskDefaultAnswer extends ToDoTaskAnswer {
    type: TaskAnswerType;
}

export interface ToDoTaskReview {
    remark?: string;
    creator: string;
    createdAt: string;
    approved?: boolean;
}

export interface ToDoTask {
    type: ToDoTaskType;
    id: number;
    // Can contain HTML-Code
    question: string;
    // Can contain HTML-Code
    description?: string;
    optional: boolean;
    answers?: ToDoTaskAnswer[];
    defaultAnswer?: ToDoTaskDefaultAnswer;
    numberType?: NumberType;
    config?: ToDoTaskConfiguration;
    order: number;
    //
    answerValueToName: Record<string, string>;
    requiresReview: boolean;
    // Boolean indicator to check whether this task can be edited (e.g. was already reviewed and approved => frozen = true)
    frozen: boolean;
    approved: boolean;
    reviews?: ToDoTaskReview[];
}

export enum ToDoTopCategoryColumnType {
    Text = 'TEXT',
    Task = 'QUESTION',
    Empty = 'EMPTY',
    Table = 'TABLE',
    Headline = 'HEADLINE',
    Headline2 = 'HEADLINE_2'
}

export interface ToDoTopCategoryColumnWidth {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
}

export interface ToDoCategoryColumn {
    type: ToDoTopCategoryColumnType;
    text?: string;
    questionID?: number;
    width: ToDoTopCategoryColumnWidth;
    hideQuestionText: boolean;
    rows?: ToDoCategoryRow[];
    minWidth?: number;
}

export interface ToDoCategoryRow {
    columns: ToDoCategoryColumn[];
    marginBottom?: string;
    wrapColumns?: boolean;
}

export interface ToDoCategoryLayout {
    rows: ToDoCategoryRow[];
}

export interface ToDoTopCategoryLayoutDTO {
    layout: ToDoCategoryLayout;
    id: number;
}

export enum ToDoTopCategoryProgress {
    NONE = 'NONE',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE'
}

export interface ToDoCategory {
    id: number;
    name: string;
    helpText?: string;
    questions: ToDoTask[];
    layout?: ToDoTopCategoryLayoutDTO;
    progress: ToDoTopCategoryProgress;
}

export interface ToDoAttachmentConfiguration {
    title?: string;
    elements: ToDoTaskConfigurationElement[];
}

export enum LoadPreviousToDoConfigurationType {
    LoadAsynchronously = 'LoadAsynchronously'
}

export enum LoadPreviousToDoVisualizationType {
    Inline = 'Inline',
    Sidebar = 'Sidebar'
}

export interface LoadPreviousToDoConfiguration {
    type: LoadPreviousToDoConfigurationType;
    numberOfPreviousToDos: number;
    includeNonFinishedToDos: boolean;
    visualizationType: LoadPreviousToDoVisualizationType
}

export interface LoadAsynchronouslyLoadPreviousToDoConfiguration extends LoadPreviousToDoConfiguration {
    type: LoadPreviousToDoConfigurationType.LoadAsynchronously;
}

export interface ToDoConfiguration {
    elements: ToDoConfigurationElement[];
    previousToDoConfiguration?: LoadPreviousToDoConfiguration;
    preFinishConfiguration: boolean;
    finishButtonText?: CompiledMessage;
}

export enum ToDoConfigurationElementType {
    SkipOverview = 'skipOverview'
}

export interface ToDoConfigurationElement {
    type: ToDoConfigurationElementType;
}

export interface SkipOverviewToDoConfigurationElement extends ToDoConfigurationElement {
    type: ToDoConfigurationElementType.SkipOverview;
    skipOverview: boolean;
}

export interface ToDoEditor {
    id: number;
    name: string;
}

export interface EditToDo {
    id: number;
    name: string;
    answers: TaskAnswer[];
    questions: ToDoTask[];
    topCategories: ToDoCategory[];
    // Dictionary of dictionary, activeFileUploads[questionId][fileId]
    activeFileUploads: Record<number, Record<string, UploadFile>>;
    // Dictionary of dictionary, errorFileUploads[questionId][fileId]
    // If error happens during upload, than file uploads will be stored here
    errorFileUploads: Record<number, Record<string, UploadFile>>;
    // Dictionary of dictionary, finishedFileUploads[questionId][fileId]
    finishedFileUploads: Record<number, Record<string, AnswerAttachment>>;
    // Dictionary which holds the current save fetch status of a question
    taskFetchStatus: Record<string, FetchStatus>;
    // Dictionary which holds the finish state of each question
    questionState: Record<number, boolean>;
    // Dictionary which holds whether a task is currently locked or not
    taskLastUserChanged: Record<number, number>;
    // Dictionary which holds the last timestamp when the syncing has started
    taskLastSyncStarted: Record<number, number>;
    // Pages can contain multiple categories
    pages: ToDoCategoryPage[];
    fetchStatus: {
        finish?: FetchStatus;
        preFinish?: FetchStatus;
        autoApprove?: FetchStatus;
        autoDecline?: FetchStatus;
    };
    lastUpdateTimestamp?: number;
    createdAt: number;
    inMemoryImages: Record<string, File>;
    attachmentConfiguration?: ToDoAttachmentConfiguration;
    configuration?: ToDoConfiguration;
    requiresReview: boolean;
    reviewedBy: string[];
    preFinishResults?: PreFinishToDoResult[];
    editors: ToDoEditor[];
    reviewMessageConfiguration?: ToDoReviewMessageConfiguration;
}

export enum SyncToDoAnswerType {
    Single = 'single',
    Multiple = 'multiple'
}

export interface ReviewToDoRemark {
    id?: number;
    taskId: number;
    remark?: string;
    approved?: boolean;
    updatedAt?: string;
    createdAt?: string;
    fetchStatus?: FetchStatus;
    toDoId: number;
}

export interface ReviewToDo {
    id: number;
    toDoId: number;
    name: string;
    remarks: ReviewToDoRemark[];
    reviewers: Person[];
    tasks: ToDoTask[];
    updatedAt: string;
    answers: TaskAnswer[];
    editors: string[];
    createdAt: string;
    finishedAt?: string;
    finishedBy?: Person;
    deadline: string;
    finishFetchStatus?: FetchStatus;
    reOpenFetchStatus?: FetchStatus;
    canBeReOpenedByUser: boolean;
    declinedReason?: CompiledMessage;
    declinedDate?: string;
    declinedBy?: string;
    autoDeclined: boolean;
}

export enum ToDoReviewApprovalConfigurationType {
    Default = 'Default',
    TimeBasedRestricted = 'timeBasedRestricted'
}

export interface ToDoReviewApprovalConfiguration {
    type: ToDoReviewApprovalConfigurationType
}

export interface TimeBaseRestrictedToDoReviewApprovalConfiguration extends ToDoReviewApprovalConfiguration {
    type: ToDoReviewApprovalConfigurationType.TimeBasedRestricted;
    requiredDate: boolean;
    description?: CompiledMessage;
}

export enum ToDoReviewDeclineConfigurationType {
    Default = 'default'
}

export interface ToDoReviewDeclineConfiguration {
    type: ToDoReviewDeclineConfigurationType;
    buttonText?: CompiledMessage;
    explanationRequired: boolean;
    confirmationDialogDescription?: CompiledMessage;
    confirmationDialogTitle?: CompiledMessage;
    successMessage?: CompiledMessage;
    errorMessage?: CompiledMessage;
}

export interface ToDoReviewMessageConfiguration {
    title?: CompiledMessage;
    description?: CompiledMessage;
    reviewerDescription?: CompiledMessage;
    submit?: CompiledMessage;
    success?: CompiledMessage;
    error?: CompiledMessage;
}

export interface ToDoReviewConfiguration {
    review: ReviewToDo;
    declineConfiguration?: ToDoReviewDeclineConfiguration;
    approvalConfiguration?: ToDoReviewApprovalConfiguration;
}

export enum PreFinishToDoResultType {
    AutoApprove = 'AutoApprove',
    AutoDecline = 'AutoDecline',
    ReviewNeeded = 'ReviewNeeded',
    Reviewer = 'Reviewer'
}

export interface PreFinishToDoResult {
    type: PreFinishToDoResultType;
}

export interface AutoApproveDeclineMessageConfiguration {
    description: CompiledMessage;
    title: CompiledMessage;
    confirm: CompiledMessage;
}

export interface AutoApprovePreFinishResult extends PreFinishToDoResult {
    type: PreFinishToDoResultType.AutoApprove;
    message?: AutoApproveDeclineMessageConfiguration;
}

export interface AutoDeclinePreFinishResult extends PreFinishToDoResult {
    type: PreFinishToDoResultType.AutoDecline;
    message?: AutoApproveDeclineMessageConfiguration;
}

export interface ReviewNeededPreFinishResult extends PreFinishToDoResult {
    type: PreFinishToDoResultType.ReviewNeeded;
}

export interface ReviewersPreFinishResult extends PreFinishToDoResult {
    type: PreFinishToDoResultType.Reviewer;
    reviewers: string[];
}

export interface OperationsState {
    overview: ToDoPaginationState;
    requestOverview: ToDoPaginationState;
    finishedToDos: ToDoPaginationState;
    approvedRequests: ToDoPaginationState;
    declinedRequests: ToDoPaginationState;
    expiredRequests: ToDoPaginationState;
    reviewToDos: ToDoPaginationState;
    reviewRequests: ToDoPaginationState;
    userReviewToDos: ToDoPaginationState;
    userReviewRequests: ToDoPaginationState;
    subordinatedToDos: ToDoPaginationState;
    subordinatedUserReviewToDos: ToDoPaginationState;
    subordinatedUserReviewRequests: ToDoPaginationState;
    reviews: {
        elements: Record<string, ReviewToDo | undefined>;
        fetchStatus: Record<string, FetchStatus | undefined>;
        declineFetchStatus: Record<string, FetchStatus | undefined>;
        declineConfigurations: Record<string, ToDoReviewDeclineConfiguration | undefined>;
        approvalConfigurations: Record<string, ToDoReviewApprovalConfiguration | undefined>;
    };
    finishedReviews: {
        elements: Record<string, ReviewToDo>;
        fetchStatus: Record<string, FetchStatus>;
    };
    toDoGroups: {
        elements: Record<string, ToDoGroup>;
        toDosByGroup: Record<string, number[]>;
    };
    manualToDos: {
        elements: Record<string, Questionnaire>;
        createdToDoId?: number;
    };
    availableToDos: {
        elements: Record<string, Questionnaire>;
    };
    editToDos: {
        elements: Record<string, EditToDo | undefined>;
        fetchStatus: Record<string, FetchStatus>;
    };
    archiveToDos: {
        elements: Record<string, EditToDo>;
        fetchStatus: Record<string, FetchStatus>;
    };
    editorSearch: {
        elements: Record<string, Editor>;
        searchResult: Record<string, Editor>;
        selected: number[];
        fetchStatus: FetchStatus;
    };
    previousToDos: {
        elements: Record<number, EditToDo[] | undefined>;
        fetchStatus: Record<number, FetchStatus | undefined>;
    };
    fetchStatus: {
        manualToDos: FetchStatus;
        createManualToDo: FetchStatus;
        continueEmail: FetchStatus;
        availableToDos: FetchStatus;
        openToDosByToDoDefinitionAndLocation: FetchStatus;
    };
}